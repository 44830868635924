import { useState, useEffect, useCallback, useContext } from 'react';
import { SessionContext } from '../components/context/SessionContext';


export default function useUserConfig() {
    // save current window width in the state object


    //ATENCION USAR CONFIG QUE SE ENCUENTRA EN LA SESIÓN!!!!!!!!!!
    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    const { session: sessionContext, setSession: setSessionContext } = useContext(SessionContext);
    const [configState, setConfigState] = useState({});

    const loadConfig = async () => {
        setSessionContext({                   // object that we want to update
            ...sessionContext,    // keep all other key-value pairs
            config: []       // update the value of specific key
        });
        setReturnHook({
            get: getConfig,
            set: setConfig
        });
        fetch(`${process.env.PUBLIC_URL}/api/config/Get`)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                console.log(result.response)
                if (result.response != null) {
                    let finalResult = {};
                    result.response.map((e) => {
                        finalResult[e.name] = e.value;
                    });
                    setSessionContext({                   // object that we want to update
                        ...sessionContext,    // keep all other key-value pairs
                        config: finalResult       // update the value of specific key
                    });
                    setReturnHook({
                        get: getConfig,
                        set: setConfig
                    });
                    return true;
                } else {
                    return false;
                }
            })
            .catch((er) => {
                throw 'Network error'
                return false;
            });
    }
    const setConfig = async (configName, configValue) => {
        let finalResult = sessionContext.config;
        finalResult[configName] = configValue;
        setSessionContext({                   // object that we want to update
            ...sessionContext,    // keep all other key-value pairs
            config: finalResult       // update the value of specific key
        });
        fetch(`${process.env.PUBLIC_URL}/api/config/Put?name=${configName}&value=${configValue}`,
            {
                method: 'PUT',
                body: '',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                console.log(result.response)
                if (result.response != null) {
                    let finalResult = {};
                    result.response.map((e) => {
                        finalResult[e.name] = e.value;
                    });
                    setSessionContext({                   // object that we want to update
                        ...sessionContext,    // keep all other key-value pairs
                        config: finalResult       // update the value of specific key
                    });
                    return true;
                } else {
                    return false;
                }
            })
            .catch((er) => {
                throw 'Network error'
                return false;
            });
    }
    const getConfig =  (configName, configDefaultValue) => {
        if (sessionContext.config[configName] == undefined || sessionContext.config[configName] == null) {
            setConfig(configName, configDefaultValue);
            return configDefaultValue;
        } else {
            return sessionContext.config[configName];
        }
    }
    useEffect(() => {
        loadConfig();
    }, []);
    let [returnHook, setReturnHook] = useState({
        get: getConfig,
        set: setConfig
    });

    return returnHook;
}