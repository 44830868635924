import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import logo from '../img/logo_gest_cabecera.png';
import "./Layout.css";
import { SessionContext } from './context/SessionContext';
import { Link, useHistory } from 'react-router-dom';

import ajustes from '../img/ajustes.png';
import users from '../img/users.png';
import logout from '../img/log_out.png';
import auditoria from '../img/auditoria.png';
import select_open_white_left from '../img/select_open_white_left.png';
import select_open_white_right from '../img/select_open_white_right.png';
import { NotificationIndicator } from './notifications/NotificationIndicator';
import useTimeout from '../hooks/useTimeout';


export const Layout = ({ children }) => {
    let history = useHistory();
    const [drawerState, setDrawerState] = useState({
        open: false,
        enabled: true
    });
    const [notificationsState, setNotificationsState] = useState([]);
    const { session: sessionContext, setSession: setSessionContext } = useContext(SessionContext);

    const onLogout= async () => {
        const response = await fetch(`${process.env.PUBLIC_URL}/api/session`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setSessionContext(null);
    }
    const closeDrawer = () => { setDrawerState({ open: false, enabled: drawerState.enabled }) };
    const openDrawer = () => { setDrawerState({ open: true, enabled: drawerState.enabled }) };

    const fillComesFrom = () => {
        let currentUrl = history.location.pathname;
        let backlog = [];
        if (history.location.state != undefined && history.location.state.comesFrom != undefined && history.location.state.comesFrom != "") {
            backlog = history.location.state.comesFrom;
        }
        if (backlog.length==0 || backlog[backlog.length - 1] != currentUrl) backlog.push(currentUrl);
        return backlog;
    }
    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }
    useEffect(async () => {
        await fetch(`${process.env.PUBLIC_URL}/api/notifications/Get`)
            .then(handleErrors)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                setNotificationsState(result.response);
                return result.response;
            })
            .catch(() => { throw 'Network error' });
    }, []);
    useTimeout(async () => {
        await fetch(`${process.env.PUBLIC_URL}/api/notifications/Get`)
            .then(handleErrors)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                setNotificationsState(result.response);
                return result.response;
            })
            .catch(() => { throw 'Network error' });
    }, 30000);


    return (
        <>
            {(sessionContext != null && sessionContext.isActive) ?
                <div className="layout-component">
                    <div className={drawerState.open ? "draweroverlay opened" : "draweroverlay"} onClick={() => { setDrawerState({ open: false, enabled: drawerState.enabled }) }}></div>
                    <div className={drawerState.open ? "drawer opened" : "drawer"} >
                        <div className="header" style={{ cursor:"pointer" }} onClick={closeDrawer} >
                            <div style={{ width: "30px" }} /> <div>{`Hola, ${sessionContext.user.name}`}</div>  <div style={{ flex: "auto" }} /> <img src={select_open_white_right} /> <div style={{ width: "20px" }} />
                        </div>
                        <div className="body-container">
                            <div className="body">
                                {sessionContext.user.userType >= 1 ? <p style={{ marginTop: 30 }}>Configuración</p> : null}
                                {sessionContext.user.userType === 2 ? <Link replace={true} to={{ pathname: "/states", state: { comesFrom: fillComesFrom() } }} onClick={closeDrawer} className="drawer-link" ><img src={ajustes} /><div >Config. de estados</div></Link> : null}
                                {sessionContext.user.userType === 2 ? <Link replace={true} to={{ pathname: "/procedures", state: { comesFrom: fillComesFrom() } }} onClick={closeDrawer} className="drawer-link" ><img src={ajustes} /><div >Config. tipos de trámites</div></Link> : null}
                                {sessionContext.user.userType === 2 ? <Link replace={true} to={{ pathname: "/users", state: { comesFrom: fillComesFrom() } }} onClick={closeDrawer} className="drawer-link" ><img src={users} /><div >Config. de usuarios</div></Link> : null}
                                {sessionContext.user.userType >= 1 ? <Link replace={true} to={{ pathname: "/history", state: { comesFrom: fillComesFrom() } }} onClick={closeDrawer} className="drawer-link" ><img src={auditoria} /><div >Auditoría</div></Link> : null}
                                <div style={{ height: 70 }}></div>
                                <Link to="#" onClick={onLogout} className="drawer-link" ><img src={logout} /><div>Cerrar sesión</div></Link>
                                <div style={{ flex: "auto" }}></div>
                                <div style={{ height: 60, width: 260, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#FFFFFF", textAlign: "center" }}>
                                    Código de gestoría:
                                    <br />
                                    {sessionContext.gestoria.codigo?sessionContext.gestoria.codigo:"XXXX-XXXX-XXXX"}
                                </div>
                                <div style={{ height: 30 }}></div>
                            </div>
                        </div>
                    </div>
                    <div className="header">
                        <img className="logo-cabecera" src={logo} onClick={() => { history.replace("/", { comesFrom: [] }); }} />
                        <div style={{ flex: "auto" }}></div>
                        <NotificationIndicator notifications={notificationsState} setNotifications={setNotificationsState} />
                        <div className="drawer-button" style={{ cursor: "pointer" }} onClick={openDrawer} ><div style={{ width: "30px" }} /> <div>{`Hola, ${sessionContext.user.name}`}</div>  <div style={{ flex: "auto" }} /> <img src={select_open_white_left} /><div style={{ width: "20px" }} /> </div>
                    </div>

                    <div className="body">
                        <Container>
                            {children}
                        </Container>
                    </div>
                </div>
                :
                <p>ERROR!</p>
            }
        </>
    )
}

