import { Popover } from 'devextreme-react'
import React, { useState } from 'react'
import imgInfo from '../../img/info.png';

export const InfoPopover = ({ content }) => {
    const [openState, setOpenState] = useState(false)
    const toggleOpenState = () => {
        setOpenState(!openState);
    }
    return (
        <>
            <a
                id="link1"
                onMouseEnter={toggleOpenState}
                onMouseLeave={toggleOpenState}
            ><img style={{marginLeft: 5}} src={imgInfo} /></a>
            <Popover
                  target="#link1"
                  position="right"
                  width={300}
                  visible={openState}
                >
                {content}
            </Popover>
        </>
    )
}
