import React, { useContext, useState } from 'react';
import { Field, Formik, Form } from 'formik';
import { Button } from 'devextreme-react/button';
import { TextBox } from 'devextreme-react/text-box';
import "./Login.css";
import logo from '../../img/logo_dfserver_big_blanco.png'; // with import
import { SessionContext } from '../context/SessionContext';
import { Link } from 'react-router-dom';



// A custom validation function. This must return an object
// which keys are symmetrical to our values/initialValues
const validate = values => {
    const errors = {};

    if (!values.password) {
        errors.password = 'Requerido';
    } else if (values.password.length > 20) {
        errors.password = 'Must be 20 characters or less';
    }

    if (!values.email) {
        errors.email = 'Requerido';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }

    return errors;
};


export const Login = () => {
    //const loginForm = useFormik({
    //    initialValues: {
    //        email: '',
    //        password: ''
    //    },
    //    validate,
    //    onSubmit: values => {
    //        alert(JSON.stringify(values, null, 2));
    //    },
    //});
    const [loginState, setLoginState] = useState({ loading: false, email: null, password: null, result: null, message: "" });
    const { session: sessionContext, setSession: setSessionContext } = useContext(SessionContext);
    const DXTextInput = ({fieldName, fieldType, fieldPlaceholder, ...rest }) => (
        <Field  name={fieldName} id={fieldName} type={fieldType} placeholde={fieldPlaceholder} >
            {({ field, form }) => (
                <TextBox {...rest}
                    name={field.name}
                    placeholder={fieldPlaceholder}
                    mode={fieldType}
                    valueChangeEvent="keyup"
                    onKeyUp={event => {
                        form.setFieldValue(field.name, event.component.option("value"), true)
                    }}
                    onChange={event => {
                        form.setFieldValue(field.name, event.component.option("value"), true)
                    }}
                    onFocusOut={event => {
                        form.setFieldTouched(field.name)
                    }}
                    value={field.value}
                />
            )}
        </Field>
    );
    const loginFormOptions = {
        initialValues: {
            email: '',
            password: ''
        },
        validate: validate ,
        onSubmit: async values => {
            setLoginState({
                loading: true, email: values.email, password: values.password, result: null, message: ""
            });
            //alert(JSON.stringify(values, null, 2));
            const response = await fetch(`${process.env.PUBLIC_URL}/api/session`, {
                method: 'POST',
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setLoginState({
                loading: false, email: values.email, password: values.password, result: data.response, message: data.message
            });
            if (data.response != null) {
                data.response.shownInitialAlert = false;
                setSessionContext(data.response);
            }
        }
    };
    return (

        <div className="login-component">
            <div className="banner">
                <img className="logo-df" src={logo} />
            </div>
            <div className="body">
                <Formik {...loginFormOptions} >
                    {
                        ({ isSubmitting, ...props }) => {
                            const hasInputError = (props.touched.password && props.errors.password) || (props.touched.email && props.errors.email);
                            const hasFetchError = (!loginState.loading && loginState.email == props.values.email && loginState.password == props.values.password && loginState.result == null);
                            const hasError = (hasInputError || hasFetchError);
                            return <Form className="login-form" >
                                <h1>Inicio de sesión</h1>
                                <label htmlFor="email">Correo electrónico: *</label>
                                <DXTextInput disabled={isSubmitting} fieldName="email" fieldType="email" className={hasError ? "login-input-error" : ""} fieldPlaceholder="Introduce tu correo" inputAttr={{autocomplete: 'on' }} />
                                <label style={{ marginTop: "12px" }} htmlFor="password">Contraseña: *</label>
                                <DXTextInput disabled={isSubmitting} fieldName="password" fieldType="password" className={hasError ? "login-input-error" : ""} fieldPlaceholder="Introduce tu contraseña" />
                                {hasError?
                                    <div className="login-error">
                                        {props.touched.email && props.errors.email && props.errors.email == "Requerido" || props.touched.password && props.errors.password && props.errors.password == "Requerido" ?
                                            "Todos los campos son obligatorios"
                                            :
                                            loginState.result == null && (loginState.message != "" && loginState.message != "No session") ? loginState.message :"El correo o la contraseña no son válidos"
                                        }
                                        {/*{hasFetchError ?*/}
                                        {/*    "El correo o la contraseña no son válidos"*/}
                                        {/*    :*/}
                                        {/*    ""*/}
                                        {/*}*/}
                                    </div> :
                                    null
                                }

                                <Button disabled={isSubmitting} style={{ marginTop:  (hasError? "23px" : "28px"), fontSize: "20px", color: "var(--azul-df)" }} className="login-form-button"
                                    text="Acceder"
                                    type="danger"
                                    useSubmitBehavior={true}
                                    onClick={async(event) => {
                                            event.element.classList.remove("dx-state-active");
                                        }
                                    }
                                />
                                <Link replace={true} style={{ marginTop: "38px" }} to="./forgot" replace={true} >¿Has olvidado la contraseña?</Link>
                            </Form>
                        }
                    }
                </Formik>
            </div>
        </div>
    )
}
