import React, { useContext, useEffect, useState } from 'react';
import { Field, Formik, Form } from 'formik';
import { Button } from 'devextreme-react/button';
import { TextBox } from 'devextreme-react/text-box';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string'


import logo from '../../img/logo_gest_cabecera.png'; 
import check_rounded_big from '../../img/check_rounded_big.png'; 
import "./PasswordRecovery.css";



// A custom validation function. This must return an object
// which keys are symmetrical to our values/initialValues
const validate = values => {
    const errors = {};
    if (!values.email) {
        errors.email = 'Requerido';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'El correo introducido no es válido';
    }

    return errors;
};
const validateConfirm = values => {
    const errors = {};
    var securityValidator = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (!values.password) {
        errors.password = 'Requerido';
    } else if (!values.password.match(securityValidator)) {
        errors.password = 'Debe tener una longitud de entre 8 y 15 carácteres y contener al menos una letra minúscula, una letra mayúscula, un número y un carácter especial.';
    }
    if (!values.passwordConfirm) {
        errors.passwordConfirm = 'Requerido';
    } else if (values.passwordConfirm != values.password) {
        errors.passwordConfirm = 'Las contraseñas no coinciden. Vuelve a introducirlas de nuevo.';
    }

    return errors;
};
const DXTextInput = ({ fieldName, fieldType, fieldPlaceholder, ...rest }) => (
    <Field name={fieldName} id={fieldName} type={fieldType} placeholde={fieldPlaceholder} >
        {({ field, form }) => (
            <TextBox {...rest}
                name={field.name}
                placeholder={fieldPlaceholder}
                mode={fieldType}
                valueChangeEvent="keyup"
                onKeyUp={event => {
                    form.setFieldValue(field.name, event.component.option("value"), true)
                }}
                onChange={event => { form.setFieldValue(field.name, event.component.option("value"), true) }}
                onFocusOut={event => { form.setFieldTouched(field.name) }}
                value={field.value}
            />
        )}
    </Field>
);

export const PasswordRecovery = () => {
    let history = useHistory();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    console.log(queryParams);
    const queryEmail = queryParams.email || "";
    const queryRecovery = queryParams.recovery || "";


    //const filter = queryParams.q || "";

    const [dialogState, setDialogState] = useState({
        title: "",
        header: "",
        body: "",
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showDialog = (title, header, body) => {
        setDialogState({
            title: title,
            header: header,
            body: body,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
    }

    const hideDialog = () => {
        setDialogState({
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }
    const [recoveryStep, setRecoveryStep] = useState("start");
    const [recoveryState, setRecoveryState] = useState({ loading: false, email: null, result: null });
    const [recoveryConfirmState, setRecoveryConfirmState] = useState({ loading: false, password: null, passwordConfirm: null, result: null });




    useEffect(async () => {
        if (queryEmail != "" && queryRecovery != "") {
            const response = await fetch(`${process.env.PUBLIC_URL}/api/recovery/check?email=${queryEmail}&recovery=${queryRecovery}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            if (data.response) {
                setRecoveryStep("confirm");
            } else {
                setRecoveryStep("start");
            }
        }
    })

    const recoveryFormOptions = {
        initialValues: {
            email: ''
        },
        validate: validate,
        onSubmit: async values => {
            setRecoveryState({
                loading: true, email: values.email, result: null
            });
            const response = await fetch(`${process.env.PUBLIC_URL}/api/recovery/start?email=${values.email}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            showDialog("Correo electrónico de cambio de contraseña enviado",
                "Correo electrónico de cambio de contraseña enviado.",
                "En breve, recibirás el correo. Si no es así, revisa el correo electrónico introducido o revisa la bandeja de SPAM por si hubiese llegado allí.");
            setRecoveryState({
                loading: false, email: values.email, result: data.response
            });
        }
    };
    const recoveryConfirmFormOptions = {
        initialValues: {
            password: '',
            passwordConfirm: ''
        },
        validate: validateConfirm,
        onSubmit: async values => {
            setRecoveryConfirmState({
                loading: true, password: values.password, passwordConfirm: values.passwordConfirm, result: false
            });
            const response = await fetch(`${process.env.PUBLIC_URL}/api/recovery/confirm?email=${queryEmail}&recovery=${queryRecovery}&password=${values.password}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            if (data.response) {
                showDialog("Contraseña cambiada",
                    "Se ha cambiado tu contraseña con éxito.",
                    "Utiliza tu nueva contraseña para iniciar sesión a continuación.");
            } else {

            }
            
            setRecoveryConfirmState({
                loading: false, password: values.password, passwordConfirm: values.passwordConfirm, result: data.response
            });
        }
    };
    return (
        <div className="password-recovery-component">
            <div className="header">
                <img className="logo-cabecera" src={logo} />
            </div>
            <div className="body">
                {(dialogState.popupVisible ?
                    null
                    :
                    (recoveryStep == "start" ?
                        <Formik key="recovery-form" {...recoveryFormOptions} >
                            {
                                ({ isSubmitting, ...props }) => {
                                    const hasInputError = (props.touched.email && props.errors.email);
                                    const hasFetchError = (!recoveryState.loading && recoveryState.email == props.values.email && recoveryState.result == null);
                                    const hasError = (hasInputError || hasFetchError);
                                    return <Form className="recovery-form" >
                                        <h1>¿Has olvidado la contraseña?</h1>
                                        <p>Introduce tu correo electrónico y te enviaremos un correo para restablecer la contraseña.</p>
                                        <label htmlFor="email">Correo electrónico: *</label>
                                        <DXTextInput disabled={isSubmitting} fieldName="email" fieldType="email" className={hasError ? "recovery-input-error" : ""} fieldPlaceholder="Introduce tu correo" />
                                        {hasError ?
                                            <div className="recovery-error">
                                                {props.touched.email && props.errors.email && props.errors.email == "Requerido" ?
                                                    "Todos los campos son obligatorios"
                                                    :
                                                    "El correo no es válido"
                                                }
                                            </div> :
                                            null
                                        }
                                        <div className="buttons-container">
                                            <Button disabled={isSubmitting} className="recovery-form-button"
                                                text="Cancelar"
                                                stylingMode="outlined"
                                                type="normal"
                                                onClick={event => {
                                                        event.element.classList.remove("dx-state-active");
                                                        let currentUrl = history.location.pathname;
                                                        let backlog = [];
                                                        if (history.location.state != undefined && history.location.state.comesFrom != undefined && history.location.state.comesFrom != "") {
                                                            backlog = history.location.state.comesFrom;
                                                        }
                                                        backlog.push(currentUrl)
                                                        history.replace("./", { comesFrom: backlog });
                                                    }
                                                }
                                            />
                                            <Button disabled={isSubmitting} className="recovery-form-button"
                                                text="Enviar"
                                                type="danger"
                                                useSubmitBehavior={true}
                                                onClick={event => {
                                                    event.element.classList.remove("dx-state-active");
                                                }
                                                }
                                            />
                                        </div>

                                        <p style={{"marginTop": 40 }} >En breve, recibirás el correo. Si no es así, revisa el correo electrónico introducido o revisa la bandeja de SPAM por si hubiese llegado allí.</p>
                                    </Form>
                                }
                            }
                        </Formik>
                        :
                        <Formik key="recovery-confirm-form" {...recoveryConfirmFormOptions} >
                            {
                                ({ isSubmitting, ...props }) => {
                                    const hasInputError = (props.touched.password && props.errors.password || props.touched.passwordConfirm && props.errors.passwordConfirm);
                                    const hasFetchError = (!recoveryConfirmState.loading && recoveryConfirmState.password == props.values.password && recoveryConfirmState.result == false);
                                    const hasError = (hasInputError || hasFetchError);
                                    return <Form className="recovery-confirm-form" >
                                        <h1>Cambiar contraseña</h1>
                                        <label htmlFor="password">Nueva contraseña: *</label>
                                        <DXTextInput style={{ marginTop: 5, marginBottom: 10 }} disabled={isSubmitting} fieldName="password" fieldType="password" className={hasError ? "recovery-input-error" : ""} fieldPlaceholder="Introduce tu nueva contraseña" />
                                        <label style={{marginTop:"10px"}} htmlFor="password">Confirmar nueva contraseña: *</label>
                                        <DXTextInput style={{ marginTop: 5, marginBottom: 10 }} disabled={isSubmitting} fieldName="passwordConfirm" fieldType="password" className={hasError ? "recovery-input-error" : ""} fieldPlaceholder="Confirma tu nueva contraseña" />
                                        {hasError ?
                                            <div className="recovery-error">
                                                {props.touched.password && props.errors.password && props.errors.password == "Requerido" || props.touched.passwordConfirm && props.errors.passwordConfirm && props.errors.passwordConfirm == "Requerido" ?
                                                    "Todos los campos son obligatorios"
                                                    :
                                                    props.errors.password || props.errors.passwordConfirm
                                                    //(props.touched.password && props.errors.password && props.errors.password == "Demasiado corta") ?
                                                    //    "La contraseña es demasiado corta"
                                                    //    :
                                                    //    "Las contraseñas no coinciden. Vuelve a introducirlas."
                                                }
                                            </div> :
                                            null
                                        }
                                        <div className="buttons-container">
                                            <Button disabled={isSubmitting} className="recovery-form-button"
                                                text="Cancelar"
                                                stylingMode="outlined"
                                                type="default"
                                                onClick={event => {
                                                    event.element.classList.remove("dx-state-active");
                                                    let currentUrl = history.location.pathname;
                                                    let backlog = [];
                                                    if (history.location.state != undefined && history.location.state.comesFrom != undefined && history.location.state.comesFrom != "") {
                                                        backlog = history.location.state.comesFrom;
                                                    }
                                                    backlog.push(currentUrl)
                                                    history.replace("./", { comesFrom: backlog });
                                                }
                                                }
                                            />
                                            <Button disabled={isSubmitting} className="recovery-form-button"
                                                text="Enviar"
                                                type="danger"
                                                useSubmitBehavior={true}
                                                onClick={event => {
                                                    event.element.classList.remove("dx-state-active");
                                                }
                                                }
                                            />
                                        </div>

                                    </Form>
                                }
                            }
                        </Formik>
                    )
                )}
                <Popup
                        animation={{ hide: null }}
                        onShown={(e) => {
                            e.component.repaint();
                        }}
                        visible={dialogState.popupVisible}
                        onHiding={hideDialog}
                        dragEnabled={false}
                        closeOnOutsideClick={false}
                        showCloseButton={false}
                        showTitle={true}
                        title={dialogState.title}
                        container=".dx-viewport"
                        height="auto"
                        width="auto"
                        minWidth="500px"
                    >
                    <Position
                        at="center"
                        my="center"
                        of="window"
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            type:"default",
                            text: 'Cerrar',
                            onClick: () => {
                                let currentUrl = history.location.pathname;
                                let backlog = [];
                                if (history.location.state != undefined && history.location.state.comesFrom != undefined && history.location.state.comesFrom != "") {
                                    backlog = history.location.state.comesFrom;
                                }
                                backlog.push(currentUrl)
                                history.replace("./", { comesFrom: backlog });
                            },
                        }}
                    />
                    {/*<ToolbarItem*/}
                    {/*    widget="dxButton"*/}
                    {/*    toolbar="bottom"*/}
                    {/*    location="after"*/}
                    {/*    options={this.closeButtonOptions}*/}
                    {/*/>*/}
                    <div style={{ display: "flex", "justifyContent":"center" }}><img src={check_rounded_big} /></div>
                    <h4 style={{ "color": "var(--azul-df)", textAlign: "left" }} >
                        {dialogState.header}
                    </h4>
                    <p style={{ "color": "var(--azul-df)", textAlign: "left" }} >
                        {dialogState.body}
                    </p>
                </Popup>
            </div>
        </div>
    )
}
