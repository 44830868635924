import React, { useState } from 'react'

import imgGoBack from '../../img/go_back.png';
import { Link, useHistory } from 'react-router-dom';
import "./History.css";
import { HistorySearch } from './HistorySearch';
import { HistoryGrid } from './HistoryGrid';
import CustomStore from 'devextreme/data/custom_store';
import { BackButton } from '../common/BackButton';
export const History = () => {


    const [searchState, setSearchState] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
        endDate: new Date(new Date().setDate(new Date().getDate() + 1)),
        user: null,
        event: null,
        tramite: "",
    });
    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }
    const userDataSource = new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: () => {
            return fetch(`${process.env.PUBLIC_URL}/api/users`)
                .then(handleErrors)
                .then(response => response.json())
                .then(result => {
                    // You can process the response here
                    return result.response;
                })
                .catch(() => { throw 'Network error' });
        }
    });

    let history = useHistory();

    return (
        <div className="history-component">
            <div className="container">
                <div className="flex">
                    <div className="header">
                        <BackButton text="Ir atrás" to="" history={history} style={{marginTop: 10}} />
                        <div className="space" />
                        {/*<h1 style={{*/}
                        {/*    marginBlockStart: "0.4em", marginBlockEnd: "0.4em"}}>Auditoría&nbsp;</h1>*/}
                    </div>
                    <div className="body">
                        <div className="left-panel">
                            <h1 style={{marginBlockStart: "0.4em", marginBlockEnd: "0.4em", marginLeft: "50px"}}>Auditoría&nbsp;</h1>
                            <HistorySearch searchState={searchState} setSearchState={setSearchState} userDataSource={userDataSource} />
                        </div>
                        <HistoryGrid searchState={searchState} />
                    </div>
                </div>
            </div>
        </div>
    )
}
