import { Button, DataGrid } from 'devextreme-react'
import { Column, Editing, Export, Item, Pager, Paging, Scrolling, SearchPanel, Selection, Toolbar, LoadPanel as DGLoadPanel } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react'
import useWindowSize from '../../hooks/useWindowSizze';
import { exportDataGrid } from 'devextreme/excel_exporter';
import parse from 'date-fns/parse'
import imgSpinner from '../../img/spinner.gif';

import "./HistoryGrid.css";

export const HistoryGrid = ({ searchState }) => {

    //{ "id", "Id" },
    //{ "event", "Event" },
    //{ "date", "Date" },
    //{ "gestoria", "GestoriaId" },
    //{ "user", "UserId" },
    //{ "state", "StateId" },
    //{ "procedure", "ProcedureId" },
    //{ "registry", "RegistryId" },
    //{ "doc", "DocId" },
    //{ "extra_doc", "ExtraDoc" },
    //{ "description", "Description" }

    const [usersState, setUsersState] = useState({ loading: true, results: [] });
    const [procedureState, setProcedureState] = useState({ loading: true, results: [] });


    const windowSize = useWindowSize();

    const [gridPaginationState, setGridPaginationState] = useState({});

    const [filterState, setFilterState] = useState(null);

    const loadProcedures = async () => {
        fetch(`${process.env.PUBLIC_URL}/api/procedures/Get`)
            .then(handleErrors)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                setProcedureState({ loading: false, results: result.response });
                return result.response;
            })
            .catch(() => { throw 'Network error' });
    }
    const loadUsers = async () => {
        fetch(`${process.env.PUBLIC_URL}/api/users`)
            .then(handleErrors)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                setUsersState({ loading: false, results: result.response });
                if (searchState.user != null && searchState.user > 0) {
                    return result.response.filter((e) => e.userId == searchState.user);
                } else {
                    return result.response;
                }
            })
            .catch(() => { throw 'Network error' });
    }
    const loadAll = async () => {
        Promise.all([loadProcedures(), loadUsers()]).then(() => {
            //setLoadingState(false);
        });
    }
    const resizeGridPagination = () => {
        try {
            var expGridShowRowCount = Math.floor(((windowSize.height - (156 + 50) - (54 * 2) - 30) / 55));
            setGridPaginationState({
                pageSize: expGridShowRowCount,
                infoText: "Mostrando {2} entradas"
            });
        } catch (ee) {

        }
    }
    const eventId2Human = (eId) => {
        //LogIn,
        //    LogOut,
        //    AssignRegistry,
        //    ChangeRegistryState,
        //    ViewRegistry,
        //    ViewFile,
        //    ExportSiga,
        //    DownloadRegistry
        if (eId == 0) {
            return "Inicia sesión"
        } else if (eId == 1) {
            return "Cierra sesión"
        } else if (eId == 2) {
            return "Asigna trámite"
        } else if (eId == 3) {
            return "Cambia estado de trámite"
        }else if (eId == 4) {
            return "Abre trámite"
        } else if (eId == 5) {
            return "Abre archivo"
        } else if (eId == 6) {
            return "Exporta a SIGA"
        } else if (eId == 7) {
            return "Descarga trámite"
        }
    }


    useEffect(loadAll, []);
    useEffect(resizeGridPagination, [windowSize]);

    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }
    const historyDataSource = new CustomStore({
        key: 'uId',
        loadMode: 'raw',
        filter: filterState,
        load: () => {
            return fetch(`${process.env.PUBLIC_URL}/api/history?fromDate=${String(searchState.startDate.getDate()).padStart(2, '0')}-${String(searchState.startDate.getMonth() + 1).padStart(2, '0')}-${searchState.startDate.getFullYear()}&toDate=${String(searchState.endDate.getDate()).padStart(2, '0')}-${String(searchState.endDate.getMonth() + 1).padStart(2, '0')}-${searchState.endDate.getFullYear()}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(result => {
                    // You can process the response here
                    var res = result.response;
                    if (searchState.user != null && searchState.user > 0) {
                        res = res.filter((e) => e.userId == searchState.user);
                    }
                    if (searchState.event != null && searchState.event > -1) {
                        res = res.filter((e) => e.event == searchState.event);
                    }
                    if (searchState.tramite != null && searchState.tramite != "") {
                        res = res.filter((e) => {
                            return e.procedureId > 0 && (`${String(e.gestoriaId).padStart(3, "0")}${String(e.procedureId).padStart(4, "0")}${String(e.registryId).padStart(6, "0")}`.endsWith(searchState.tramite) || `${String(e.gestoriaId).padStart(3, "0")}${String(e.procedureId).padStart(4, "0")}${String(e.registryId).padStart(6, "0")}`.startsWith(searchState.tramite))
                        });
                    }
                    return res;
                })
                .catch(() => { throw 'Network error' });
        }
    });

    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Trámites');
        exportDataGrid({
            //component: dGrid.getInstance(document.querySelector(".dx-datagrid")),
            component: e.component,
            worksheet: worksheet,
            selectedRowsOnly: true,
            customizeCell: function (options) {
                const excelCell = options;
                excelCell.font = { name: 'Arial', size: 12 };
                excelCell.alignment = { horizontal: 'left' };
            }
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Auditoria.xlsx');
                });
        });
        e.cancel = true;
    }


    const [exportButtonState, setExportButtonState] = useState(false);
    const onRowSelectionChanged = (e) => {
        e.component.getSelectedRowsData().then((selectedData) => {
            if (selectedData.length == 0) {
                setExportButtonState(false);
            } else {
                setExportButtonState(true);
            }
        });

    }

    return (
        <div className="grid history-grid-component">
            <DataGrid
                style={{ marginTop: 10 }}
                dataSource={historyDataSource}
                keyExpr="uId"
                showColumnLines={false}
                showRowLines={true}
                showBorders={true}
                width="100%"
                columnAutoWidth={true}
                height={useWindowSize().height - (156)}
                onExporting={onExporting}
                onSelectionChanged={onRowSelectionChanged}
                columnAutoWidth={true}
            >
                <DGLoadPanel
                    shadingColor="rgb(92 92 92 / 80%)"
                    enabled={true}
                    showIndicator={true}
                    showPane={true}
                    indicatorSrc={imgSpinner}
                    text="Cargando..."
                />
                <Export enabled={true} />
                <Selection mode="multiple" deferred={true} showCheckBoxesMode="always" />
                <Scrolling mode="standard" useNative={true} />
                <Paging defaultPageSize={10} pageSize={gridPaginationState.pageSize} />
                <Pager
                    infoText={gridPaginationState.infoText}
                    visible={true}
                    showPageSizeSelector={false}
                    showInfo={true}
                    showNavigationButtons={true} />
                {/*<SearchPanel visible={true} />*/}
                <Toolbar>
                    <Item locateInMenu="auto"
                        disabled={!exportButtonState}
                        location="after"
                        name="exportButton"
                        cssClass="export-button"
                        options={{ type: "default" }}
                        />
                    {/*<Item locateInMenu="auto"*/}
                    {/*    location="after"*/}
                    {/*    name="searchPanel" />*/}
                </Toolbar>
                <Editing
                    mode="row"
                    allowUpdating={false}
                    allowDeleting={false}
                    allowAdding={false} />
                <Column dataField="userId" caption="Usuario" dataType="string"
                    calculateCellValue={(rowData) => {
                        return (usersState.results.find((e) => e.id == rowData.userId) || { name: "Sin determinar" }).name;
                    }}
                />
                <Column dataField="date" name="sortDate" caption="sortDate" dataType="datetime" sortOrder="desc" visible={false}
                    calculateCellValue={(rowData) => {
                        let datePart = rowData.date.split(" ")[0];
                        let timePart = rowData.date.split(" ")[1];
                        let formated =  datePart.split("/")[0].padStart(2, "0") + "/" + datePart.split("/")[1].padStart(2, "0") + "/" + datePart.split("/")[2].padStart(4, "0") + " " + timePart.split(":")[0].padStart(2, "0") + ":" + timePart.split(":")[1].padStart(2, "0") + ":" + timePart.split(":")[2].padStart(2, "0");
                        return parse(formated, "dd/MM/yyyy HH:mm:ss", new Date());
                    }}
                />
                <Column dataField="date" caption="Fecha" dataType="string" allowSorting={false}
                    calculateCellValue={(rowData) => {
                        let datePart = rowData.date.split(" ")[0];
                        let timePart = rowData.date.split(" ")[1];
                        return datePart.split("/")[0].padStart(2, "0") + "/" + datePart.split("/")[1].padStart(2, "0") + "/" + datePart.split("/")[2].padStart(4, "0") + " " + timePart.split(":")[0].padStart(2, "0") + ":" + timePart.split(":")[1].padStart(2, "0") + ":" + timePart.split(":")[2].padStart(2, "0");
                    }}
                />
                <Column dataField="event" caption="Evento" dataType="string"
                    calculateCellValue={(rowData) => {
                        return eventId2Human(rowData.event);
                    }}
                />
                <Column dataField="description" caption="Descripción" dataType="string" />
                <Column dataField="procedureId" caption="Tipo de trámite" dataType="string"
                    calculateCellValue={(rowData) => {
                        return (procedureState.results.find((e) => e.id == rowData.procedureId) || { name: "Sin determinar" }).name;
                    }}
                />
                <Column dataField="registryId" caption="Trámite" dataType="string"
                    calculateCellValue={(rowData) => {
                        if (rowData.procedureId > 0) {
                            return `${String(rowData.gestoriaId).padStart(3, "0")}${String(rowData.procedureId).padStart(4, "0")}${String(rowData.registryId).padStart(6, "0") }`
                        } else {
                            return "Sin determinar";
                        }
                    }}
                />
            </DataGrid>
        </div>
    )
}
