import { Popover, List } from 'devextreme-react'
import React, { useEffect, useState } from 'react'
import imgNotificationIndicator from '../../img/campanita.png';
import "./NotificationIndicator.css";
import useWindowSize from '../../hooks/useWindowSizze'
import { useHistory, useLocation } from 'react-router';


const renderListItem = (itemData) => {
    return (
        <div className="notification-indicator-component-listitem" style={{}}>
            <span style={{fontSize:"13px"}}>{itemData.date}</span>
            <br />
            {itemData.description}
        </div>
    )
}
const demoData = [
    { description: "El concesionario 'TEST' ha adjuntado el documento 'TEST' al trámite 'TEST'.", date: "15-04-1992 03:54" },
    { description: "El concesionario 'TEST' ha adjuntado el documento 'TEST' al trámite 'TEST'.", date: "15-04-1992 03:54" },
    { description: "El concesionario 'TEST' ha adjuntado el documento 'TEST' al trámite 'TEST'.", date: "15-04-1992 03:54" },
    { description: "El concesionario 'TEST' ha adjuntado el documento 'TEST' al trámite 'TEST'.", date: "15-04-1992 03:54" },
    { description: "El concesionario 'TEST' ha adjuntado el documento 'TEST' al trámite 'TEST'.", date: "15-04-1992 03:54" },
    { description: "El concesionario 'TEST' ha adjuntado el documento 'TEST' al trámite 'TEST'.", date: "15-04-1992 03:54" },
    { description: "El concesionario 'TEST' ha adjuntado el documento 'TEST' al trámite 'TEST'.", date: "15-04-1992 03:54" },
    { description: "El concesionario 'TEST' ha adjuntado el documento 'TEST' al trámite 'TEST'.", date: "15-04-1992 03:54" },
    { description: "El concesionario 'TEST' ha adjuntado el documento 'TEST' al trámite 'TEST'.", date: "15-04-1992 03:54" },
    { description: "El concesionario 'TEST' ha adjuntado el documento 'TEST' al trámite 'TEST'.", date: "15-04-1992 03:54" },
    { description: "El concesionario 'TEST' ha adjuntado el documento 'TEST' al trámite 'TEST'.", date: "15-04-1992 03:54" },
    { description: "El concesionario 'TEST' ha adjuntado el documento 'TEST' al trámite 'TEST'.", date: "15-04-1992 03:54" },
    { description: "El concesionario 'TEST' ha adjuntado el documento 'TEST' al trámite 'TEST'.", date: "15-04-1992 03:54" },
    { description: "El concesionario 'TEST' ha adjuntado el documento 'TEST' al trámite 'TEST'.", date: "15-04-1992 03:54" }
];
export const NotificationIndicator = ({ notifications, setNotifications }) => {
    const [openState, setOpenState] = useState(false)
    const toggleOpenState = () => {
        setOpenState(!openState);
    }
    const location = useLocation();
    const history = useHistory();

    //public int Id { get; set; }
    //public int GestoriaId { get; set; }
    //public int UserId { get; set; }
    //public int ProcedureId { get; set; }
    //public int RegistryId { get; set; }
    //public string Description { get; set; } = String.Empty;
    //public string Date { get; set; } = String.Empty;
    //public int Seen { get; set; }
    //public int ConcesionarioId { get; set; }

    useEffect(() => { console.log(location) }, []);
    const windowSize = useWindowSize();

    return (
        <>
            <div
                onClick={toggleOpenState}
                className="notification-indicator-component" style={{ cursor: "pointer", position: "relative" }}>
                <div className="notification-indicator-component-active-indicator" >
                    <a
                        style={{ borderRadius: "8px" }}
                        id="link1"
                    >
                    <img className="notification-indicator-component-bell" src={imgNotificationIndicator} />
                        <div style={(notifications.length > 0 ? { display: "initial" } : { display: "none" }) } className="notification-indicator-component-new" ></div></a>
                </div>
            </div>
            <Popover
                onShowing={() => { document.getElementsByClassName("notification-indicator-component-active-indicator")[0].classList.add("active"); }}
                onHiding={() => {
                    setOpenState(false);
                    document.getElementsByClassName("notification-indicator-component-active-indicator")[0].classList.remove("active");
                }}
                elementAttr={{ class: "notification-indicator-component-popover" }}
                onContentReady={
                    (args) => {  
                        var html = args.component.content();
                        html.style.padding = 0;
                        html.style.borderRadius = "8px";
                                //$(html).css("padding", "0");  
                    }  
                }
                target="#link1"
                position="bottom"
                width={300}
                visible={openState}
            >
                <h4 className="notification-indicator-component-header">Notificaciones</h4>
                <div className="notification-indicator-component-body">
                    <List
                        elementAttr={{ class: "notification-indicator-component-list" }}
                        itemRender={renderListItem}
                        noDataText="No hay notificaciones"
                        displayExpr="Description"
                        dataSource={notifications}
                        onItemClick={(e) => {
                            let notUrl = `/registry/${e.itemData.concesionarioId}/${e.itemData.procedureId}/${e.itemData.registryId}`;
                            setNotifications(notifications.filter((each) => {
                                return (e.itemData.registryId != each.registryId || e.itemData.concesionarioId != each.concesionarioId || e.itemData.procedureId != each.procedureId);
                            }));
                            setOpenState(false);
                            history.push(notUrl);
                        }}
                        height={() => {
                            const headerSize = 45;
                            const bottomSize = 150;
                            const toobarSize = 50;
                            const listItemSize = 105;
                            let size = windowSize.height - 150;
                            let notBasedSize = (notifications.length * listItemSize);
                            if ((bottomSize + toobarSize + headerSize) + notBasedSize >= windowSize.height) {
                                return size;
                            } else {
                                return notBasedSize;
                            }
                        }}
                        allowItemDeleting={false}>
                    </List>
                </div>
            </Popover>
        </>
    )
}
