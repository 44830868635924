import { DateBox, SelectBox, TextBox } from 'devextreme-react';
import React from 'react'

import "./HistorySearch.css";

export const HistorySearch = ({ searchState, setSearchState, userDataSource }) => {

    const disableStartDates = (args) => {
        return args.date > searchState.endDate
    }
    const disableEndDates = (args) => {
        return (args.date > new Date(new Date().setDate(new Date().getDate() +1)) || args.date < searchState.startDate);
    }
    return (
        <div className="search history-search-component">
            <div className="header">
                {/*<h4 style={{ marginRight: 5 }}><div class="dx-icon dx-icon-search" style={{ marginRight: 5 }}></div>Filtros</h4>*/}
                <h4 style={{ marginRight: 5 }}>Filtros</h4>
            </div>
            <div className="body">
                <label htmlFor="startDate">Fecha inicial</label>
                <DateBox style={{ marginBottom: 15, marginTop: 5 }} name="startDate" defaultValue={new Date(new Date().setDate(new Date().getDate() - 7))}
                    type="date"
                    pickerType="calendar"
                    value={searchState.startDate }
                    onValueChanged={(e) => {
                        setSearchState((prevState => ({
                            ...prevState,
                            startDate: e.value
                        })))
                    }}
                    displayFormat="dd/MM/yyyy"
                    disabledDates={disableStartDates} />
                <label htmlFor="endDate">Fecha final</label>
                <DateBox style={{ marginBottom: 15, marginTop: 5 }} name="endDate" defaultValue={new Date(new Date().setDate(new Date().getDate() +1))}
                    type="date"
                    displayFormat="dd/MM/yyyy"
                    pickerType="calendar"
                    value={searchState.endDate}
                    onValueChanged={(e) => {
                        setSearchState((prevState => ({
                            ...prevState,
                            endDate: e.value
                        })))
                    }}
                    disabledDates={disableEndDates} />
                <label htmlFor="user">Usuario</label>
                <SelectBox style={{ marginBottom: 15, marginTop: 5 }} name="user"
                    showClearButton={true}
                    placeholder="Escoge un usuario"
                    valueExpr="id"
                    displayExpr="name"
                    dataSource={userDataSource}
                    onValueChanged={event => {
                        setSearchState((prevState => ({
                            ...prevState,
                            user: event.component.option("value")
                        })))
                    }}
                    value={searchState.user} />
                <label htmlFor="event">Evento</label>
                <SelectBox style={{ marginBottom: 15, marginTop: 5 }} name="event"
                    showClearButton={true}
                    placeholder="Escoge un evento"
                    valueExpr="id"
                    displayExpr="name"
                    dataSource={[
                        { id: 0, name: "Inicia sesión"},
                        { id: 1, name: "Cierra sesión"},
                        { id: 2, name: "Asigna trámite"},
                        { id: 3, name: "Cambia estado de trámite"},
                        { id: 4, name: "Accede a trámite"},
                        { id: 5, name: "Accede a documento"},
                        { id: 6, name: "Exporta a SIGA" },
                        { id: 7, name: "Descarga trámite" },
                        { id: 8, name: "Añade documento extra" },
                        { id: 9, name: "Elimina documento extra" },
                        { id: 10, name: "Sube documento" }
                    ]}
                    onValueChanged={event => {
                        setSearchState((prevState => ({
                            ...prevState,
                            event: event.component.option("value")
                        })))
                    }}
                    value={searchState.event} />
                <label htmlFor="tramite">Trámite</label>
                <TextBox style={{ marginBottom: 15, marginTop: 5 }} name="tramite"
                    showClearButton={true}
                    placeholder="Identificador del trámite"
                    valueChangeEvent="keyup"
                    onValueChanged={event => {
                        setSearchState((prevState => ({
                            ...prevState,
                            tramite: event.component.option("value")
                        })))
                    }}
                    value={searchState.tramite} />
            </div>
        </div>
    )
}
