import { Button, Popup, TextBox } from 'devextreme-react';
import React, { useEffect, useRef, useState } from 'react'
import noDoc from '../../NO_DOC.pdf';
import imgEdit from '../../img/edit.png';
import imgDelete from '../../img/delete.png';
import imgUpload from '../../img/upload.png';
import imgDownload from '../../img/download.png';
import "./LocalDocuments.css";
import { Field, Form, Formik } from 'formik';
import { Position, ToolbarItem } from 'devextreme-react/popup';
import { Document, Page, pdfjs } from 'react-pdf';
import { DocumentViewer } from './DocumentViewer';

export const LocalDocuments = ({ idRegistry, reload, enabled, localProcedureDocuments, freeDocuments }) => {

    //ESTO ESTÄ AQUI PARA SOLUCIONAR UN PROBLEMA QUE DA LIVE RELOAD CUANDO SE TIENE UN IFRAME EN LA PÁGINA!!!
    //QUITAR ANTES DEL DEPLOY!!!!
    useEffect(() => {
        window.process = {
            env: {
                NODE_ENV: 'development'
            }
        }
    }, []);
    //HASTA AQUI


    const addFormRef = useRef();
    const uploadFormRef = useRef();

    const [selectedDocState, setSelectedDocState] = useState({ free: false, id: 0 });
    const [selectedDocB64State, setSelectedDocB64State] = useState("");

    const [localProcedureDocumentsState, setLocalProcedureDocumentsState] = useState({ loading: true, result: [] });
    const [freeDocumentsState, setFreeDocumentsState] = useState({ loading: true, result: [] });

    const [addExtraState, setAddExtraState] = useState({ loading: false, name: null, error: false, result: null });



    const [addExtraDialogState, setAddExtraDialogState] = useState({
        popupVisible: false,
    });
    const showAddExtraDialog = () => {
        if (!enabled) return;
        setAddExtraDialogState({
            popupVisible: true,
        });
        if (addFormRef != undefined && addFormRef != null && addFormRef.current != undefined && addFormRef.current != null) {
            addFormRef.current.setFieldValue("name", "", false);
            addFormRef.current.setFieldValue("file", null, false);
        }
    }
    const hideAddExtraDialog = () => {
        setAddExtraDialogState({
            popupVisible: false,
        });
    }


    const [uploadExtraState, setUploadExtraState] = useState({ loading: false, idDoc: 0, name: null, error: false, result: null });


    const [uploadExtraDialogState, setuploadExtraDialogState] = useState({
        popupVisible: false,
    });
    const showUploadExtraDialog = (idDoc, alreadyHasDoc) => {
        setuploadExtraDialogState({
            idDoc: idDoc,
            popupVisible: true,
        });
        if (uploadFormRef != undefined && uploadFormRef != null && uploadFormRef.current != undefined && uploadFormRef.current != null) {
            uploadFormRef.current.setFieldValue("file", null, false);
        }
    }
    const hideUploadExtraDialog = () => {
        setuploadExtraDialogState({
            idDoc: 0,
            popupVisible: false,
        });
    }

    const [confirmDeleteDialogState, setConfirmDeleteDialogState] = useState({
        idDoc: 0,
        popupVisible: false,
    });
    const showConfirmDeleteDialog = (idDoc) => {
        setConfirmDeleteDialogState({
            idDoc: idDoc,
            popupVisible: true,
        });
    }
    const hideConfirmDeleteDialog = () => {
        setConfirmDeleteDialogState({
            idDoc: 0,
            popupVisible: false,
        });
    }

    const loadLocalProcedureDocuments = async () => {
        setLocalProcedureDocumentsState({ loading: true, result: [] });
        if (Number(idRegistry) > 0) {
            fetch(`${process.env.PUBLIC_URL}/api/registries/GetRegistryDocs?id=${idRegistry}&remote=0&extra=0`)
                .then(response => response.json())
                .then(result => {
                    // You can process the response here
                    console.log(result.response)
                    if (result.response != null) {
                        setLocalProcedureDocumentsState({ loading: false, result: result.response });

                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((er) => {
                    throw 'Network error'
                    return false;
                });
        }
    };
    const loadExtraDocuments = async () => {
        setFreeDocumentsState({ loading: true, result: [] });
        if (Number(idRegistry) > 0) {
            fetch(`${process.env.PUBLIC_URL}/api/registries/GetRegistryDocs?id=${idRegistry}&remote=0&extra=1`)
                .then(response => response.json())
                .then(result => {
                    // You can process the response here
                    console.log(result.response)
                    if (result.response != null) {
                        setFreeDocumentsState({ loading: false, result: result.response });

                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((er) => {
                    throw 'Network error'
                    return false;
                });
        }
    };


    const DXTextInput = ({ fieldName, fieldType, fieldPlaceholder, ...rest }) => (
        <Field name={fieldName} id={fieldName} type={fieldType} placeholde={fieldPlaceholder} >
            {({ field, form }) => (
                <TextBox {...rest}
                    name={field.name}
                    placeholder={fieldPlaceholder}
                    mode={fieldType}
                    onChange={event => { form.setFieldValue(field.name, event.component.option("value"), true) }}
                    onFocusOut={event => { form.setFieldTouched(field.name) }}
                    value={field.value}
                />
            )}
        </Field>
    );

    const validateAddExtra = values => {
        const errors = {};

        if (!values.name) {
            errors.name = 'Requerido';
        } else if (values.name.length > 150) {
            errors.name = 'Must be 150 characters or less';
        }
        if (!values.file) {
            errors.name = 'Requerido';
        } 
        return errors;
    };
    const validateuploadExtra = values => {
        const errors = {};


        if (!values.file) {
            errors.name = 'Requerido';
        }
        return errors;
    };

    const addExtraFormOptions = {
        initialValues: {
            name: '',
            file: null,
            remote: 0
        },
        validate: validateAddExtra,
        onSubmit: async values => {
            setAddExtraState({
                loading: true, name: values.name, error: false, result: null
            });
            //alert(JSON.stringify(values, null, 2));
            //PostRegistryExtraDoc(int idRegistry, string name, int isRemote)


            let formData = new FormData();
            formData.append('file', values.file);

            const response = await fetch(`${process.env.PUBLIC_URL}/api/registries/PostRegistryExtraDoc?idRegistry=${idRegistry}&name=${values.name}&isRemote=0`, {
                method: 'POST',
                body: formData,
                //headers: {
                //    'Content-Type': 'multipart/form-data'
                //}
            });
            const data = await response.json();
            setAddExtraState({
                loading: false, name: values.name, error: (data.response == null), result: (data.response != null ? data.response : data.message)
            });
            if (data.response != null) {
                hideAddExtraDialog();
                reload();
                loadAll();
            }
        }
    };
    const uploadExtraFormOptions = {
        initialValues: {
            file: null,
            id: 0
        },
        validate: validateuploadExtra,
        onSubmit: async values => {

            setSelectedDocState({ free: false, id: 0 });
            setUploadExtraState({
                loading: true, idDoc: uploadExtraDialogState.idDoc, error: false, result: null
            });
            //alert(JSON.stringify(values, null, 2));
            //PostRegistryExtraDoc(int idRegistry, string name, int isRemote)

            let formData = new FormData();
            formData.append('file', values.file);
            const response = await fetch(`${process.env.PUBLIC_URL}/api/registries/PostRegistryDoc?idRegistry=${idRegistry}&idDoc=${uploadExtraDialogState.idDoc}&isRemote=0`, {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            setUploadExtraState({
                loading: false, idDoc: uploadExtraDialogState.idDoc, error: (data.response == null), result: (data.response != null ? data.response : data.message)
            });
            if (data.response != null) {
                hideUploadExtraDialog();
                reload();
                loadAll();
            }
        }
    };


    useEffect(() => {
        let b64 = "";
        if (selectedDocState.id > 0) {
            if (selectedDocState.free) {
                if (!freeDocumentsState.loading) b64 = freeDocumentsState.result.find(e => e["item2"].id == selectedDocState.id)["item3"];
            } else {
                if (!localProcedureDocumentsState.loading) b64 = localProcedureDocumentsState.result.find(e => e["item2"].id == selectedDocState.id)["item3"];
            }
            setSelectedDocB64State(b64);
        } else {
            setSelectedDocB64State("");
        }
    }, [selectedDocState]);

    const loadAll = async () => {
        Promise.all([loadLocalProcedureDocuments(), loadExtraDocuments()]).then(() => {
            
        });
    }
    useEffect(loadAll, []);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    });

    return (
        <div className="local-documents-component">
            <div className="container">
                {/*<Document*/}
                {/*    file={noDoc}*/}
                {/*    onLoadSuccess={onDocumentLoadSuccess}*/}
                {/*    >*/}
                {/*    <Page pageNumber={pageNumber} />*/}
                {/*</Document>*/}
                <DocumentViewer selectedDocB64={selectedDocB64State}
                    viewerWidth={"100%"} elmId="local-documents-component-viewer" />
                <div style={{ width: "75%" }} >
                    <h4>Documentos a aportar por la gestoría</h4>
                    <div className="listHeader" style={{ marginBottom: 10, width: "calc(100% - 90px)", cursor: "default", display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
                        <div style={{ width: "100%" }}>Nombre</div>
                        <div style={{ width: "110px" }}></div>
                    </div>
                    {
                        (
                            (localProcedureDocumentsState.result || []).length > 0
                            ?
                            (localProcedureDocumentsState.result || []).map((d) => {
                                const hasDocument = (d["item3"] != null && d["item3"] != "");
                                const isSelected = (selectedDocState.free==false && selectedDocState.id > 0 && selectedDocState.id == d["item2"].id)
                                let classNames = "doc-list-item";
                                if (hasDocument) {
                                    classNames += " doc-list-item-filled"
                                }
                                if (isSelected) {
                                    classNames += " doc-list-item-selected"
                                }

                                return (
                                    <div key={`local_doc_${d["item2"].id}`} style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
                                        <div className={classNames} onClick={() => {
                                            setSelectedDocState({ free: false, id: d["item2"].id });
                                            //d["item2"]
                                        }} >
                                            <div style={{ width: "100%", paddingLeft: 10 }}>{d["item2"].name}{d["item2"].mandatory?" *":""}</div>
                                        </div>
                                        <div style={{ width: "110px" }}>
                                            <Button
                                                width={40}
                                                style={{ marginLeft: 10 }}
                                                disabled={!enabled }
                                                elementAttr={{ "data-id": d["item2"].id }}
                                                icon={imgUpload}
                                                onClick={() => {
                                                        showUploadExtraDialog(d["item2"].id, hasDocument)
                                                    }
                                                }
                                            />
                                            {!hasDocument ?
                                                null
                                                :
                                                <>
                                                    <Button
                                                        width={40}
                                                        style={{ marginLeft: 10 }}
                                                        disabled={!hasDocument}
                                                        elementAttr={{ "data-id": d["item2"].id }}
                                                        icon={imgDownload}
                                                        onClick={() => {
                                                            fetch(`${process.env.PUBLIC_URL}/api/registries/GetRegistryDocExtension?idRegistro=${idRegistry}&idProcedure=${d.item2.procedureId}&idDocumento=${d.item2.id}&remote=0&extra=0`)
                                                                .then(response => response.json())
                                                                .then(result => {
                                                                    // You can process the response here
                                                                    console.log(result.response)
                                                                    if (result.response != null) {
                                                                        var a = document.createElement("a");
                                                                        a.href = `${d["item3"]}`;
                                                                        a.download = `${d["item2"].name}.${result.response}`;
                                                                        a.click();
                                                                        return true;
                                                                    } else {
                                                                        return false;
                                                                    }
                                                                })
                                                                .catch((er) => {
                                                                    throw 'Network error'
                                                                    return false;
                                                                });
                                                            }
                                                        }
                                                    />
                                                </>
                                            }
                                           
                                            {/*<Button*/}
                                            {/*    width={40}*/}
                                            {/*    style={{ marginLeft: 10 }}*/}
                                            {/*    disabled={!enabled || !hasDocument}*/}
                                            {/*    elementAttr={{ "data-id": d[0].id }}*/}
                                            {/*    icon={imgDelete}*/}
                                            {/*/>*/}
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div style={{ textAlign: "center", padding: 5, border: "solid var(--azul-df) 0.5px", fontFamily: "roboto-regular", marginTop: 20 }}>Sin documentos</div>
                        )
                    }
                    <div style={{ display: "flex", alignItems: "center"}}>
                        <h4>Documentos adicionales</h4>
                        <div style={{flex: "auto"}} />
                        <span style={enabled ? { textDecoration: "underline", cursor: "pointer" } : { textDecoration: "underline", cursor: "default", color: "#7A7A7A" }} onClick={showAddExtraDialog} >Añadir nuevo documento</span>
                    </div>
                    <div className="listHeader" style={{ marginBottom: 10, width: "calc(100% - 110px)", cursor: "default", display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
                        <div style={{ width: "100%" }}>Nombre</div>
                        <div style={{ width: "110px" }}></div>
                    </div>
                    {
                        (
                            (freeDocumentsState.result || []).length > 0
                            ?
                                (
                                    (freeDocumentsState.result || []).map((d) => {
                                        const hasDocument = (d["item3"] != null && d["item3"] != "");
                                        const isSelected = (selectedDocState.free == true && selectedDocState.id > 0 && selectedDocState.id == d["item2"].id)
                                        let classNames = "doc-list-item";
                                        if (hasDocument) {
                                            classNames += " doc-list-item-filled"
                                        }
                                        if (isSelected) {
                                            classNames += " doc-list-item-selected"
                                        }

                                        return (
                                            <div key={`extra_local_doc_${d["item2"].id}`} style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
                                                <div className={classNames} onClick={() => {
                                                    setSelectedDocState({ free: true, id: d["item2"].id });
                                                    //d["item2"]
                                                }} >
                                                    <div style={{ width: "100%", paddingLeft: 10 }}>{d["item2"].name}</div>
                                                </div>
                                                <div style={{ width: "110px" }}>
                                                    {hasDocument ?
                                                        <>
                                                            <Button
                                                                width={40}
                                                                style={{ marginLeft: 10 }}
                                                                disabled={!hasDocument}
                                                                elementAttr={{ "data-id": d["item2"].id }}
                                                                icon={imgDownload}
                                                                onClick={() => {
                                                                        fetch(`${process.env.PUBLIC_URL}/api/registries/GetRegistryDocExtension?idRegistro=${idRegistry}&idProcedure=${d.item2.procedureId}&idDocumento=${d.item2.id}&remote=0&extra=1`)
                                                                            .then(response => response.json())
                                                                            .then(result => {
                                                                                // You can process the response here
                                                                                console.log(result.response)
                                                                                if (result.response != null) {
                                                                                    var a = document.createElement("a");
                                                                                    a.href = `${d["item3"]}`;
                                                                                    a.download = `${d["item2"].name}.${result.response}`;
                                                                                    a.click();
                                                                                    return true;
                                                                                } else {
                                                                                    return false;
                                                                                }
                                                                            })
                                                                            .catch((er) => {
                                                                                throw 'Network error'
                                                                                return false;
                                                                            });
                                                                    }
                                                                }
                                                            />
                                                            <Button
                                                                width={40}
                                                                style={{ marginLeft: 10 }}
                                                                disabled={!enabled || !hasDocument}
                                                                elementAttr={{ "data-id": d["item2"].id }}
                                                                icon={imgDelete}
                                                                onClick={() => { showConfirmDeleteDialog(d["item2"].id) }}
                                                                />
                                                        </>
                                                        :
                                                        <>
                                                            <Button
                                                                width={40}
                                                                style={{ marginLeft: 10 }}
                                                                disabled={!enabled || hasDocument}
                                                                elementAttr={{ "data-id": d["item2"].id }}
                                                                icon={imgDelete}
                                                                onClick={() => { showConfirmDeleteDialog(d["item2"].id) }}
                                                                />
                                                        </>
                                                    }
                                                    
                                                    {/*<Button*/}
                                                    {/*    width={40}*/}
                                                    {/*    style={{ marginLeft: 10 }}*/}
                                                    {/*    disabled={!enabled || !hasDocument}*/}
                                                    {/*    elementAttr={{ "data-id": d[0].id }}*/}
                                                    {/*    icon={imgDelete}*/}
                                                    {/*/>*/}
                                                </div>
                                            </div>
                                        )
                                    }
                                )
                            )
                            :
                            <div style={{ textAlign: "center", padding: 5, border: "solid var(--azul-df) 0.5px", fontFamily: "roboto-regular", marginTop: 20 }}>Sin documentos</div>
                        )
                    }
                </div>
            </div>
            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={addExtraDialogState.popupVisible}
                onHiding={hideAddExtraDialog}

                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={`Añadir nuevo documento`}
                container=".dx-viewport"
                height="auto"
                width="auto"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                <div style={{ display: "flex", "justifyContent": "center" }}> </div>
                <Formik {...addExtraFormOptions}
                    innerRef={addFormRef}  >
                    {
                        ({ isSubmitting, handleReset, setFieldTouched, setFieldValue, ...props }) => {
                            const hasInputError = (props.touched.name && props.errors.name);
                            const hasFetchError = false; //(!addExtraState.loading && addExtraState.email == props.values.email && addExtraState.name == props.values.name && addExtraState.type == props.values.type && (addExtraState.result == null || addExtraState.error));
                            const hasError = (hasInputError || hasFetchError);
                            return <Form className="add-extra-form" >
                                <p>Introduce el nombre del nuevo documento que quieres añadir al trámite</p>
                                <label style={{ fontFamily: "roboto-medium" }} htmlFor="name">Nombre del documento: *</label>
                                <DXTextInput style={{ marginBottom: "12px" }} disabled={isSubmitting} fieldName="name" fieldType="text" className={hasError ? "add-extra-input-error" : ""} fieldPlaceholder="Introduce un nombre" />
                                <label style={{ fontFamily:"roboto-medium"}} htmlFor="file">Nuevo documento a añadir: *</label><br />
                                <span >Pulsa el botón para seleccionar el archivo o arrástralo al área de abajo</span>
                                <input className="file-input" style={{
                                    marginTop: 5,
                                    width: "100%", padding: "5px", border: "1px solid var(--azul-df)" }} disabled={isSubmitting} id="file" name="file" type="file" onChange={(event) => {
                                    setFieldValue("file", event.currentTarget.files[0]);
                                }}  />
                                {hasError ?
                                    <div className="add-extra-error">
                                        {props.touched.name && props.errors.name && props.errors.name == "Requerido" ?
                                            "Todos los campos son obligatorios"
                                            :
                                            addExtraState.error ? addExtraState.result : "Se han proporcionado datos inválidos"
                                        }
                                    </div> :
                                    null
                                }

                                
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ flex: "auto" }} />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", width: "auto", flexGrow: 0 }}
                                        text="Cancelar"
                                        type="normal"
                                        useSubmitBehavior={false}
                                        onClick={event => {
                                                event.element.classList.remove("dx-state-active");
                                                event.element.classList.remove("dx-state-focused");
                                                handleReset();
                                                setFieldTouched("email", false, false);
                                                setFieldTouched("name", false, false);
                                                setFieldTouched("type", false, true);
                                                hideAddExtraDialog();
                                            }
                                        }
                                    />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", marginLeft: "10px", width: "auto", flexGrow: 0 }}
                                        text="Guardar"
                                        type="default"
                                        useSubmitBehavior={true}
                                        onClick={event => {
                                                event.element.classList.remove("dx-state-active");
                                                event.element.classList.remove("dx-state-focused");

                                            }
                                        }
                                    />
                                </div>
                            </Form>
                        }
                    }
                </Formik>
            </Popup>
            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={uploadExtraDialogState.popupVisible}
                onHiding={hideUploadExtraDialog}

                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={`Añadir documento`}
                container=".dx-viewport"
                height="auto"
                width="auto"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                <div style={{ display: "flex", "justifyContent": "center" }}> </div>
                <Formik {...uploadExtraFormOptions}
                    innerRef={uploadFormRef}  >
                    {
                        ({ isSubmitting, handleReset, setFieldTouched, setFieldValue, ...props }) => {
                            const hasInputError = (props.touched.name && props.errors.name);
                            const hasFetchError = false; //(!uploadExtraState.loading && uploadExtraState.email == props.values.email && uploadExtraState.name == props.values.name && uploadExtraState.type == props.values.type && (uploadExtraState.result == null || uploadExtraState.error));
                            const hasError = (hasInputError || hasFetchError);
                            return <Form className="add-extra-form" >
                                <label style={{ fontFamily: "roboto-medium" }} htmlFor="file">Documento a enviar: *</label><br />
                                <span >Pulsa el botón para seleccionar el archivo o arrástralo al área de abajo</span>
                                <input className="file-input" style={{
                                    marginTop: 5,
                                    width: "100%", padding: "5px", border: "1px solid var(--azul-df)"
                                }} disabled={isSubmitting} id="file" name="file" type="file" onChange={(event) => {
                                    setFieldValue("file", event.currentTarget.files[0]);
                                }} />
                                {hasError ?
                                    <div className="add-extra-error">
                                        {props.touched.name && props.errors.name && props.errors.name == "Requerido" ?
                                            "Todos los campos son obligatorios"
                                            :
                                            addExtraState.error ? addExtraState.result : "Se han proporcionado datos inválidos"
                                        }
                                    </div> :
                                    null
                                }


                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ flex: "auto" }} />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", width: "auto", flexGrow: 0 }}
                                        text="Cancelar"
                                        type="normal"
                                        useSubmitBehavior={false}
                                        onClick={event => {
                                                event.element.classList.remove("dx-state-active");
                                                event.element.classList.remove("dx-state-focused");
                                                handleReset();
                                                setFieldTouched("name", false, false);
                                                hideUploadExtraDialog();
                                            }
                                        }
                                    />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", marginLeft: "10px", width: "auto", flexGrow: 0 }}
                                        text="Guardar"
                                        type="default"
                                        useSubmitBehavior={true}
                                        onClick={event => {
                                                event.element.classList.remove("dx-state-active");
                                                event.element.classList.remove("dx-state-focused");
                                                
                                            }
                                        }
                                    />
                                </div>
                            </Form>
                        }
                    }
                </Formik>
            </Popup>
            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={confirmDeleteDialogState.popupVisible}
                onHiding={hideConfirmDeleteDialog}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={`Eliminando documento adicional`}
                container=".dx-viewport"
                height="auto"
                width="auto"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: "No, cancelar",
                        type: "normal",
                        onClick: (event) => {
                            event.element.classList.remove("dx-state-active");
                            event.element.classList.remove("dx-state-focused");
                            hideConfirmDeleteDialog();
                        },
                    }}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        type: "default",
                        text: 'Si, eliminar',
                        onClick:  async(event) => {
                            event.element.classList.remove("dx-state-active");
                            event.element.classList.remove("dx-state-focused");
                            const response = await fetch(`${process.env.PUBLIC_URL}/api/registries/DeleteRegistryExtraDoc?idRegistry=${idRegistry}&idDoc=${confirmDeleteDialogState.idDoc}`, {
                                method: 'DELETE',
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            });
                            const data = await response.json();
                            if (data.response != null) {
                                reload();
                                loadAll();
                            }
                            hideConfirmDeleteDialog();
                        }
                    }}
                />
                {/*<div style={{ display: "flex", "justifyContent": "center" }}><img src={check_rounded_big} /></div>*/}
                <p style={{ textAlign: "left", fontFamily: 'roboto-regular' }} >
                    ¿Estás seguro de que deseas eliminar permanentemente este documento adicional?
                </p>
            </Popup>
        </div>
    )
}
