import React from 'react'
import { Redirect, Route } from 'react-router'

export const ConditionalPrivateRoute = ({
    children,
    isAuthenticated,
    condition,
    ...rest
}) => {
    return (
        <Route  {...rest} >
            {isAuthenticated && condition ? (
                children
            ) : (
                <Redirect to='/' />
            )}
        </Route >
    )
}