import React from 'react'
import { Redirect, Route } from 'react-router'

export const PrivateRoute = ({
        children,
        isAuthenticated,
        ...rest
    }) => {
    return (
        <Route  {...rest} >
            {isAuthenticated ? (
                children
            ) : (
                <Redirect to='/login' />
            )}
        </Route >
    )
}
//export const PrivateRoute = ({
//    isAuthenticated,
//    component: Component,
//    ...rest
//}) => {
//    return (
//        <Route  {...rest}
//            component={(props) => {
//                return (isAuthenticated)
//                    ? (<Component {...props} />)
//                    : (<Redirect to="./login" />)
//            }}
//        />
//    )
//}