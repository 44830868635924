import { useEffect, useState } from "react"
import { Column, Editing, Export, Item, Pager, Paging, Scrolling, SearchPanel, Selection, Toolbar, LoadPanel as DGLoadPanel } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import "./RegistryEvents.css";
import imgSpinner from '../../img/spinner.gif';
import parse from 'date-fns/parse'
import { DataGrid, LoadPanel } from "devextreme-react";

export const RegistryEvents = ({ idRegistry }) => {
    const [loadingState, setLoadingState] = useState(false);
    const [registryEventsState, setRegistryEventsState] = useState({ loading: true, result: null });
    const [filterState, setFilterState] = useState(null);
    //const loadRegistryEvents = async () => {
    //    if (Number(idRegistry) > 0) {
    //        fetch(`/api/registries/GetRegistryEvents?id=${idRegistry}`)
    //            .then(response => response.json())
    //            .then(result => {
    //                // You can process the response here
    //                console.log(result.response)
    //                if (result.response != null) {
    //                    setRegistryEventsState({ loading: false, result: result.response });
    //                    return true;
    //                } else {
    //                    return false;
    //                }
    //            })
    //            .catch((er) => {
    //                throw 'Network error'
    //                return false;
    //            });
    //    } else {
    //        return false;
    //    }
    //};
    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }
    const registryDataSource = new CustomStore({
        key: 'id',
        loadMode: 'raw',
        filter: filterState,
        load: () => {
            return fetch(`${process.env.PUBLIC_URL}/api/registries/GetRegistryEvents?id=${idRegistry}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(result => {
                    // You can process the response here
                    var res = result.response;
                    //setRegistryEventsState({ loading: false, result: result.response });
                    return res;
                })
                .catch(() => { throw 'Network error' });
        }
    });

    return (
        <div className="registry-events-component">
            <DataGrid
                style={{ marginTop: 10 }}
                dataSource={registryDataSource}
                keyExpr="id"
                showColumnLines={false}
                showRowLines={true}
                showBorders={true}
                width="100%"
                columnAutoWidth={true}
                columnAutoWidth={true}
            >
                <DGLoadPanel
                    shadingColor="rgb(92 92 92 / 80%)"
                    enabled={true}
                    showIndicator={true}
                    showPane={true}
                    indicatorSrc={imgSpinner}
                    text="Cargando..."
                />
                <Scrolling mode="standard" useNative={true} />
                <Paging enabled={false} />
                <Editing
                    mode="row"
                    allowUpdating={false}
                    allowDeleting={false}
                    allowAdding={false} />
                <Column dataField="user" caption="Usuario" dataType="string" />
                <Column dataField="date" name="sortDate" caption="sortDate" dataType="datetime" sortOrder="desc" visible={false}
                    calculateCellValue={(rowData) => {
                        let datePart = rowData.date.split(" ")[0];
                        let timePart = rowData.date.split(" ")[1];
                        let formated = datePart.split("/")[0].padStart(2, "0") + "/" + datePart.split("/")[1].padStart(2, "0") + "/" + datePart.split("/")[2].padStart(4, "0") + " " + timePart.split(":")[0].padStart(2, "0") + ":" + timePart.split(":")[1].padStart(2, "0") + ":" + timePart.split(":")[2].padStart(2, "0");
                        return parse(formated, "dd/MM/yyyy HH:mm:ss", new Date());
                    }}
                />
                <Column dataField="date" caption="Fecha" dataType="string" allowSorting={false}
                    calculateCellValue={(rowData) => {
                        let datePart = rowData.date.split(" ")[0];
                        let timePart = rowData.date.split(" ")[1];
                        return datePart.split("/")[0].padStart(2, "0") + "/" + datePart.split("/")[1].padStart(2, "0") + "/" + datePart.split("/")[2].padStart(4, "0") + " " + timePart.split(":")[0].padStart(2, "0") + ":" + timePart.split(":")[1].padStart(2, "0") + ":" + timePart.split(":")[2].padStart(2, "0");
                    }}
                />
                <Column dataField="description" caption="Descripción" dataType="string"
                />
            </DataGrid>
        </div>
    );
}