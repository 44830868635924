import React from 'react'
import imgGoBack from '../../img/go_back.png';

export const BackButton = ({ text, history, to }) => {
    const handleClick = () => {
        let currentUrl = history.location.pathname;
        let backlog = [];
        if (history.location.state != undefined && history.location.state.comesFrom != undefined && history.location.state.comesFrom != "") {
            backlog = history.location.state.comesFrom;
        }
        //if (to == "" && backlog.length > 0) {
        //    backlog.pop();
        //    history.replace(backlog[backlog.length - 1], { comesFrom: backlog });
        if (to == "") {
            backlog.push(currentUrl)
            history.replace("./dealers", { comesFrom: backlog });
        } else {
            backlog.push(currentUrl)
            history.replace(to, { comesFrom: backlog });
        }
    }
    return (
        <div style={{width: "fit-content"}}>
            <div className="backButton" onClick={handleClick} >
                <img src={imgGoBack} /> { text }
            </div>
        </div>
    )
}
