import { Button, ColorBox, DataGrid, Popup, SelectBox, TextBox } from 'devextreme-react'
import React, { useRef, useState } from 'react'
import useWindowSize from '../../hooks/useWindowSizze'
import CustomStore from 'devextreme/data/custom_store';
import { Column, Button as DGButton, Editing, SearchPanel, Toolbar, Item, Scrolling, LoadPanel as DGLoadPanel } from 'devextreme-react/data-grid';
import "./States.css";
import imgDelete from '../../img/delete.png';
import imgEdit from '../../img/edit.png';
import imgGoBack from '../../img/go_back.png';
import check_rounded_big from '../../img/check_rounded_big.png';
import { Link, useHistory } from 'react-router-dom';
import { Position, ToolbarItem } from 'devextreme-react/popover';
import { Field, Form, Formik } from 'formik';
import { BackButton } from '../common/BackButton';
import { InfoPopover } from '../common/InfoPopover';
import imgSpinner from '../../img/spinner.gif';

export const States = () => {

    let history = useHistory();

    const editFormRef = useRef()

    const [createStateState, setCreateStateState] = useState({ loading: false, foreColor: null, name: null, bgColor: null, error: false,  result: null });
    const [editStateState, setEditStateState] = useState({ id: -1, loading: false, foreColor: null, name: null, bgColor: null, error: false,  result: null });
    const [stateCountState, setStateCountState] = useState(0);

    const [createDialogState, setCreateDialogState] = useState({
        title: "",
        header: "",
        body: "",
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showCreateDialog = (title, header, body) => {
        setCreateDialogState({
            title: title,
            header: header,
            body: body,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
    }
    const hideCreateDialog = () => {
        setCreateDialogState({
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }



    const [editDialogState, setEditDialogState] = useState({
        id: -1,
        name: "",
        bgColor: "",
        foreColor: "",
        title: "",
        header: "",
        body: "",
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showEditDialog = (title, id, name, bgColor, foreColor) => {
        setEditDialogState({
            id: id,
            name: name,
            bgColor: bgColor,
            foreColor: foreColor,
            title: title,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
        if (editFormRef != undefined && editFormRef != null && editFormRef.current != undefined && editFormRef.current != null) {
            editFormRef.current.setFieldValue("name", name, false);
            editFormRef.current.setFieldValue("foreColor", foreColor, false);
            editFormRef.current.setFieldValue("bgColor", bgColor, false);
        }
        
    }
    const hideEditDialog = () => {
        setEditDialogState({
            id: -1,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }




    const [mailDialogState, setMailDialogState] = useState({
        title: "",
        header: "",
        body: "",
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showMailDialog = (title, target) => {
        setMailDialogState({
            title: title,
            target: target,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
    }
    const hideMailDialog = () => {
        setMailDialogState({
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }


    const [genericDialogState, setGenericDialogState] = useState({
        title: "",
        body: "",
        okText: "",
        koText: "",
        okCallback: () => {

        },
        koCallback: () => {

        },
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showGenericDialog = (title, body, okText, koText, okCallback, koCallback) => {
        setGenericDialogState({
            title: title,
            body: body,
            okText: okText,
            koText: koText,
            okCallback: okCallback,
            koCallback: koCallback,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
    }
    const hideGenericDialog = () => {
        setGenericDialogState({
            title: "",
            body: "",
            okText: "",
            koText: "",
            okCallback: () => {

            },
            koCallback: () => {

            },
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }


    const DXTextInput = ({ fieldName, fieldType, fieldPlaceholder, ...rest }) => (
        <Field name={fieldName} id={fieldName} type={fieldType} placeholde={fieldPlaceholder} >
            {({ field, form }) => (
                <TextBox {...rest}
                    name={field.name}
                    placeholder={fieldPlaceholder}
                    mode={fieldType}
                    valueChangeEvent="keyup"
                    onKeyUp={event => {
                        form.setFieldValue(field.name, event.component.option("value"), true)
                    }}
                    onChange={event => { form.setFieldValue(field.name, event.component.option("value"), true) }}
                    onFocusOut={event => { form.setFieldTouched(field.name) }}
                    value={field.value}
                />
            )}
        </Field>
    );
    const DXComboInput = ({ fieldName, fieldType, fieldPlaceholder, ...rest }) => (
        <Field name={fieldName} id={fieldName} type={fieldType} placeholde={fieldPlaceholder} >
            {({ field, form }) => (
                <SelectBox {...rest}
                    name={field.name}
                    placeholder={fieldPlaceholder}
                    mode={fieldType}
                    onValueChanged={event => {
                        form.setFieldValue(field.name, event.component.option("value"), true)
                    }}
                    onFocusOut={event => { form.setFieldTouched(field.name) }}
                    value={field.value}
                />
            )}
        </Field>
    );
    const DXColorBoxInput = ({ fieldName, fieldType, fieldPlaceholder, ...rest }) => (
        <Field name={fieldName} id={fieldName} type={fieldType} placeholde={fieldPlaceholder} >
            {({ field, form }) => (
                <ColorBox {...rest}
                    name={field.name}
                    placeholder={fieldPlaceholder}
                    mode={fieldType}
                    dropDownButtonComponent={null}
                    dropDownButtonRender={null}
                    dropDownButtonTemplate="dropDownButton"
                    openOnFieldClick={false}
                    applyValueMode="useButtons"
                    applyButtonText="Aceptar"
                    cancelButtonText="Cancelar"
                    onValueChanged={event => {
                        form.setFieldValue(field.name, event.component.option("value"), true)
                    }}
                    onFocusOut={event => { form.setFieldTouched(field.name) }}
                    value={field.value}
                />
            )}
        </Field>
    );

    // A custom validation function. This must return an object
    // which keys are symmetrical to our values/initialValues
    const validateCreate = values => {
        const errors = {};

        if (!values.name) {
            errors.name = 'Requerido';
        } else if (values.name.length > 30) {
            errors.name = 'Must be 30 characters or less';
        }
        return errors;
    };
    const createFormOptions = {
        initialValues: {
            name: '',
            foreColor: '#000000',
            bgColor: '#FFFFFF'
        },
        validate: validateCreate,
        onSubmit: async values => {
            setCreateStateState({
                loading: true, foreColor: values.foreColor, name: values.name, bgColor: values.bgColor, error: false, result: null
            });
            //alert(JSON.stringify(values, null, 2));
            const response = await fetch(`${process.env.PUBLIC_URL}/api/states`, {
                method: 'POST',
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setCreateStateState({
                loading: false, foreColor: values.foreColor, name: values.name, bgColor: values.bgColor, error: (data.response == null), result: (data.response != null ? data.response : data.message)
            });
            if (data.response != null) {
                hideCreateDialog();
            }
        }
    };
    const editFormOptions = {
        initialValues: {
            email: editDialogState.email,
            foreColor: editDialogState.foreColor,
            bgColor: editDialogState.bgColor
        },
        validate: validateCreate,
        onSubmit: async values => {
            setEditStateState({
                id: editDialogState.id, loading: true, email: values.email, name: values.name, type: values.type, result: null
            });
            //alert(JSON.stringify(values, null, 2));
            const response = await fetch(`${process.env.PUBLIC_URL}/api/states`, {
                method: 'PUT',
                body: JSON.stringify({ ...values, id: editDialogState.id }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setEditStateState({
                id: editDialogState.id, loading: false, email: values.email, name: values.name, type: values.type, result: (data.response != null ? data.response : data.message)
            });
            if (data.response != null) {
                hideEditDialog();
            }
        }
    };

    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }
    const jsonDataSource = new CustomStore({
        key: 'id',
        loadMode: 'raw', 
        load: () => {
            return fetch(`${process.env.PUBLIC_URL}/api/states`)
                .then(handleErrors)
                .then(response => response.json())
                .then(result => {
                    // You can process the response here
                    setStateCountState(result.response.length);
                    return result.response;
                })
                .catch(() => { throw 'Network error' });
        }
    });
    const renderColorCell = (data) => {
        return <div style={{ backgroundColor: data.value, width: 50, height: 20, borderColor: "#000000", borderStyle: "solid", borderWidth: 0.2, borderRadius: 5 }}></div>;
    }
    const onActionCellPrepared = (e) => {
        if (e.rowType === "data" && e.column.caption === "Acciones") {
            if (e.data.id <= 99) {
                e.cellElement.querySelectorAll("img").forEach((each) => {
                    each.style.visibility = "hidden";
                });
            } else {
                e.cellElement.querySelectorAll("img").forEach((each) => {
                    each.style.visibility = "visible";
                });
            }
        }
    }
    const deleteState = async (stateId) => {
        const response = await fetch(`${process.env.PUBLIC_URL}/api/states?stateId=${stateId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
    }

    const systemColumnHeaderCellRender = (data) => {
        return <div class="dx-datagrid-text-content dx-text-content-alignment-left" role="presentation">{data.column.caption}<InfoPopover content="Sistema: Los estados que tienen valor entre el 0 y el 99 son estados del sistema. Estos siempre aparecerán marcados y no se pueden editar ni borrar." /></div>
    }

    return (
        <div className="states-component">
            <div className="container">
                <div className="flex">
                    <BackButton text="Ir atrás" to="" history={history} />
                    <div className="header">
                        <h1>Estados&nbsp;</h1>
                        <h4>({stateCountState} estados)</h4>
                        <div className="space" />
                        
                    </div>
                    <DataGrid
                        dataSource={jsonDataSource}
                        noDataText="Sin estados configurados"
                        keyExpr="id"
                        showColumnLines={false}
                        showRowLines={true}
                        showBorders={false}
                        width="100%"
                        height={useWindowSize().height - (156 + 50)}
                        onCellPrepared={onActionCellPrepared}
                    >
                        <DGLoadPanel
                            shadingColor="rgb(92 92 92 / 80%)"
                            enabled={true}
                            showIndicator={true}
                            showPane={true}
                            indicatorSrc={imgSpinner}
                            text="Cargando..."
                        />
                        <Scrolling mode="standard" useNative={true} />
                        <SearchPanel visible={true} placeholder="Buscar..." />
                        <Toolbar>
                            <Item locateInMenu="auto"
                                  location="after">
                                <Button
                                    text="Nuevo estado"
                                    type="default"
                                    useSubmitBehavior={false}
                                    onClick={event => {
                                            event.element.classList.remove("dx-state-active");
                                            event.element.classList.remove("dx-state-focused");
                                            showCreateDialog("Nuevo estado", "otro hola", "mashola");
                                        }
                                    }
                                />
                            </Item>
                            <Item locateInMenu="auto"
                                  location="after"
                                  name="searchPanel" />
                        </Toolbar>
                        <Editing
                            mode="row"
                            allowUpdating={true}
                            allowDeleting={true}
                            allowAdding={false} />
                        <Column dataField="id" caption="ID" dataType="string" />
                        <Column dataField="name" caption="Nombre del estado" dataType="string" />
                        <Column
                            caption="Sistema"
                            dataType="boolean"
                            headerCellRender={systemColumnHeaderCellRender}
                            calculateCellValue={(rowData) => {
                                return rowData.id <= 99 ? true : false;
                            }}
                        />
                        <Column dataField="bgColor" caption="Color de fondo" dataType="string" cellRender={renderColorCell} />
                        <Column dataField="foreColor" caption="Color de texto" dataType="string" cellRender={renderColorCell} />
                        <Column caption="Acciones" type="buttons" dataType="string">
                            {/*<DGButton name="edit" />*/}
                            {/*<DGButton name="delete" />*/}
                            <DGButton
                                visible={(e) => {
                                    return e.row.data.id >= 100; //rowData.id >= 100
                                }}
                                text="Editar"
                                stylingMode="outlined"
                                type="success"
                                icon={imgEdit}
                                hint="Editar estado"
                                onClick={(e) => {
                                    showEditDialog("Editar estado", e.row.data.id, e.row.data.name, e.row.data.bgColor, e.row.data.foreColor);
                                }}
                                render={(data) => {
                                        return <div class="dx-button" style={{ padding: 5, width: 52 }}><img src={imgEdit} class="dx-icon dx-link-icon" title="Editar estado" /></div>
                                    }
                                }
                            />
                            <DGButton
                                visible={(e) => {
                                    return e.row.data.id >= 100;
                                }}
                                text="Eliminar"
                                stylingMode="outlined"
                                type="success"
                                icon={imgDelete}
                                hint="Eliminar estado"
                                onClick={(e) => {
                                    showGenericDialog("Eliminar estado", [`¿Estás seguro de que deseas eliminar el estado `, <><b>{e.row.data.name}</b>?<br /></>, "Esta acción es irreversible y los trámites que estén en este estado pasarán a 'Sin Estado.'"], "Si, eliminar", "No, cancelar", () => { deleteState(e.row.data.id); hideGenericDialog() }, () => { hideGenericDialog() })
                                }}
                                render={(data) => {
                                    return <div class="dx-button" style={{ padding: 5, width: 52 }}><img src={imgDelete} class="dx-icon dx-link-icon" title="Eliminar estado" /></div>
                                }}
                            />
                        </Column>
                    </DataGrid>
                </div>
            </div>

            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={createDialogState.popupVisible}
                onHiding={hideCreateDialog}
                
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={createDialogState.title}
                container=".dx-viewport"
                height="auto"
                width="auto"
                minWidth="400"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                <div style={{ display: "flex", "justifyContent": "center" }}> </div>
                <Formik {...createFormOptions} >
                    {
                        ({ isSubmitting, handleReset, setFieldTouched, ...props }) => {
                            const hasInputError = (props.touched.name && props.errors.name);
                            const hasFetchError = (!createStateState.loading && createStateState.email == props.values.email && createStateState.name == props.values.name && createStateState.type == props.values.type && (createStateState.result == null || createStateState.error) );
                            const hasError = (hasInputError || hasFetchError);
                            return <Form className="create-state-form" >
                                <label htmlFor="name">Nombre: *</label>
                                <DXTextInput style={{ marginBottom: "12px" }} disabled={isSubmitting} fieldName="name" fieldType="text" className={hasError ? "create-state-input-error" : ""} fieldPlaceholder="Introduce un nombre" />
                                <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                    <div style={{ width: "100%" }}>
                                        <label htmlFor="bgColor">Color de fondo: *</label>
                                        <DXColorBoxInput disabled={isSubmitting} fieldName="bgColor" fieldType="color" defaultValue="#FFFFFF" className={hasError ? "create-state-input-error" : ""} fieldPlaceholder="Escoge un color de fondo" />
                                    </div>
                                    <div style={{ width: "100%", marginLeft: "50px" }}>
                                        <label htmlFor="foreColor">Color de texto: *</label>
                                        <DXColorBoxInput disabled={isSubmitting} fieldName="foreColor" fieldType="color" defaultValue="#000000" className={hasError ? "create-state-input-error" : ""} fieldPlaceholder="Escoge un color de letra" valueExpr="id" displayExpr="name" />
                                    </div>
                                </div>
                                {hasError ?
                                    <div className="create-state-error">
                                        {props.touched.name && props.errors.name && props.errors.name == "Requerido"  ?
                                            "Todos los campos son obligatorios"
                                            :
                                            createStateState.error ? createStateState.result : "Se han proporcionado datos inválidos"
                                        }
                                    </div> :
                                    null
                                }

                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ flex: "auto" }} />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", width: "auto", flexGrow: 0 }}
                                        text="Cancelar"
                                        type="normal"
                                        useSubmitBehavior={true}
                                        onClick={event => {
                                                event.element.classList.remove("dx-state-active");
                                                event.element.classList.remove("dx-state-focused");
                                                handleReset();
                                                setFieldTouched("bgColor", false, false);
                                                setFieldTouched("name", false, false);
                                                setFieldTouched("foreColor", false, true);
                                                hideCreateDialog();
                                            }
                                        }
                                    />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", marginLeft: "10px", width: "auto", flexGrow: 0 }}
                                        text="Guardar"
                                        type="default"
                                        useSubmitBehavior={true}
                                        onClick={event => {
                                                event.element.classList.remove("dx-state-active");
                                                event.element.classList.remove("dx-state-focused");

                                            }
                                        }
                                    />
                                </div>
                            </Form>
                        }
                    }
                </Formik>
            </Popup>
            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={editDialogState.popupVisible}
                onHiding={hideEditDialog}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={editDialogState.title}
                container=".dx-viewport"
                height="auto"
                width="auto"
                minWidth="400"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                <div style={{ display: "flex", "justifyContent": "center" }}> </div>
                <Formik {...editFormOptions}
                    innerRef={editFormRef} >
                    {
                        ({ isSubmitting, handleReset, setFieldTouched, setFieldValue, ...props }) => {
                            const hasInputError = (props.touched.password && props.errors.password) || (props.touched.email && props.errors.email);
                            const hasFetchError = (!editStateState.loading && editStateState.email == props.values.email && editStateState.name == props.values.name && editStateState.type == props.values.type && (createStateState.result == null || createStateState.error));
                            const hasError = (hasInputError || hasFetchError);
                            return <Form className="create-state-form" >
                                <label htmlFor="name">Nombre: *</label>
                                <DXTextInput style={{ marginBottom: "12px" }} disabled={isSubmitting} fieldName="name" fieldType="text" className={hasError ? "create-state-input-error" : ""} fieldPlaceholder="Introduce un nombre" />
                                <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                    <div style={{ width: "100%" }}>
                                        <label htmlFor="bgColor">Color de fondo: *</label>
                                        <DXColorBoxInput disabled={isSubmitting} fieldName="bgColor" fieldType="color" defaultValue="#FFFFFF" className={hasError ? "create-state-input-error" : ""} fieldPlaceholder="Escoge un color de fondo" />
                                    </div>
                                    <div style={{ width: "100%", marginLeft: "50px" }}>
                                        <label htmlFor="foreColor">Color de texto: *</label>
                                        <DXColorBoxInput disabled={isSubmitting} fieldName="foreColor" fieldType="color" defaultValue="#000000" className={hasError ? "create-state-input-error" : ""} fieldPlaceholder="Escoge un color de letra" valueExpr="id" displayExpr="name" />
                                    </div>
                                </div>
                                {hasError ?
                                    <div className="create-state-error">
                                        {props.touched.email && props.errors.email && props.errors.email == "Requerido" || props.touched.name && props.errors.name && props.errors.name == "Requerido" || props.touched.type && props.errors.type && props.errors.type == "Requerido" ?
                                            "Todos los campos son obligatorios"
                                            :
                                            editStateState.error ? editStateState.result :"Se han proporcionado datos inválidos"
                                        }
                                    </div> :
                                    null
                                }

                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ flex: "auto" }} />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", width: "auto", flexGrow: 0 }}
                                        text="Cancelar"
                                        type="normal"
                                        useSubmitBehavior={true}
                                        onClick={event => {
                                            event.element.classList.remove("dx-state-active");
                                            event.element.classList.remove("dx-state-focused");
                                            handleReset();
                                            setFieldTouched("email", false, false);
                                            setFieldTouched("name", false, false);
                                            setFieldTouched("type", false, true);
                                            hideEditDialog();
                                        }
                                        }
                                    />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", marginLeft: "10px", width: "auto", flexGrow: 0 }}
                                        text="Guardar"
                                        type="default"
                                        useSubmitBehavior={true}
                                        onClick={event => {
                                            event.element.classList.remove("dx-state-active");
                                            event.element.classList.remove("dx-state-focused");
                                        }
                                        }
                                    />
                                </div>
                            </Form>
                        }
                    }
                </Formik>
            </Popup>
            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={mailDialogState.popupVisible}
                onHiding={hideMailDialog}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={mailDialogState.title}
                container=".dx-viewport"
                height="auto"
                width="auto"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        type: "default",
                        text: 'Cerrar',
                        onClick: () => {
                            hideMailDialog();
                        },
                    }}
                />
                <div style={{ display: "flex", "justifyContent": "center" }}><img src={check_rounded_big} /></div>
                <h4 style={{ textAlign: "center", fontFamily: 'roboto-regular' }} >
                    Hemos enviado un correo electrónico al estado que acabas de crear.
                </h4>
                <h4 style={{ textAlign: "center", fontFamily: 'roboto-bold' }}>
                    <b>{mailDialogState.target}</b>
                </h4>
                <h4 style={{ textAlign: "center", fontFamily: 'roboto-regular' }} >
                    En él recibirá las instrucciones para acceder a la plataforma.
                </h4>
                <p style={{ textAlign: "center", fontFamily: 'roboto-regular' }} >Si te has equivocado de correo electrónico, elimina el estado y crea uno nuevo.</p>
            </Popup>
            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={genericDialogState.popupVisible}
                onHiding={hideGenericDialog}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={genericDialogState.title}
                container=".dx-viewport"
                height="auto"
                width="auto"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                {genericDialogState.koText != null ?
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            stylingMode: "outline",
                            text: genericDialogState.koText,
                            onClick: genericDialogState.koCallback,
                        }}
                    />
                    :
                    null
                }
                {genericDialogState.okText != null ?
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            type: "default",
                            text: genericDialogState.okText,
                            onClick: genericDialogState.okCallback,
                        }}
                    />
                    :
                    null
                }
                <p style={{ textAlign: "left", fontFamily: 'roboto-regular' }} >
                    {genericDialogState.body}
                </p>
            </Popup>
        </div>
    )
}
