import { Button, DataGrid, DropDownButton, List, Popup, SelectBox, TextBox } from 'devextreme-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import useWindowSize from '../../hooks/useWindowSizze'
import CustomStore from 'devextreme/data/custom_store';
import { Column, Button as DGButton, Editing, SearchPanel, Toolbar, Item, Scrolling, LoadPanel as DGLoadPanel, Sorting } from 'devextreme-react/data-grid';
import "./Procedures.css";
import imgDelete from '../../img/delete.png';
import imgEdit from '../../img/edit.png';
import imgView from '../../img/ver.png';
import imgGoBack from '../../img/go_back.png';
import check_rounded_big from '../../img/check_rounded_big.png';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Popover, { Position, ToolbarItem } from 'devextreme-react/popover';
import { Field, Form, Formik } from 'formik';
import { BackButton } from '../common/BackButton';
import imgSpinner from '../../img/spinner.gif';

export const Procedures = () => {
    const [newSubmenuOpenState, setNewSubmenuOpenState] = useState(false)
    const toggleNewSubmenuOpenState = () => {
        setNewSubmenuOpenState(!newSubmenuOpenState);
    }
    const location = useLocation();
    const history = useHistory();
    const editFormRef = useRef();


    const [createProcedureState, setCreateProcedureState] = useState({ loading: false, email: null, name: null, type: null, error: false, result: null });
    const [editProcedureState, setEditProcedureState] = useState({ id: -1, loading: false, email: null, name: null, type: null, error: false, result: null });
    const [procedureCountState, setProcedureCountState] = useState(0);
    const [defaultProceduresState, setDefaultProceduresState] = useState([]);
    const [createDefaultProcedureState, setCreateDefaultProcedureState] = useState({ loading: false, error: false, result: null });


    const [mailDialogState, setMailDialogState] = useState({
        title: "",
        header: "",
        body: "",
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showMailDialog = (title, target) => {
        setMailDialogState({
            title: title,
            target: target,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
    }
    const hideMailDialog = () => {
        setMailDialogState({
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }


    const [defaultProcedureDialogState, setDefaultProcedureDialogState] = useState({
        title: "",
        header: "",
        body: "",
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showDefaultProcedureDialog = (title) => {
        setDefaultProcedureDialogState({
            title: title,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
    }
    const hideDefaultProcedureDialog = () => {
        setDefaultProcedureDialogState({
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }



    const [importSucessDialogState, setImportSucessDialogState] = useState({
        title: "",
        header: "",
        body: "",
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showImportSucessDialog = (title) => {
        setImportSucessDialogState({
            title: title,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
    }
    const hideImportSucessDialog = () => {
        setImportSucessDialogState({
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }


    const [importDialogValueState, setImportDialogValueState] = useState("");
    const [importDialogObjectState, setImportDialogObjectState] = useState(null);
    const [importDialogState, setImportDialogState] = useState({
        title: "",
        header: "",
        body: "",
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showImportDialog = async (title) => {
        setImportDialogValueState("");
        setImportDialogObjectState(null);
        if (navigator.clipboard.readText) {
            const text = await navigator.clipboard.readText();
            let b64Parsed = "";
            let jsonParsed = "";
            try {
                b64Parsed = atob(text)
                jsonParsed = JSON.parse(b64Parsed);
                setImportDialogValueState(text);
                console.log(jsonParsed);
                if (jsonParsed.Id == undefined || jsonParsed.Data == undefined || jsonParsed.Docs == undefined || jsonParsed.Name == undefined || jsonParsed.States == undefined) {
                    //El código es inválido
                } else {
                    setImportDialogObjectState(jsonParsed);
                }
            } catch (err) {

            }
        }
        setImportDialogState({
            title: title,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
    }
    const hideImportDialog = () => {
        setImportDialogState({
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }




    const DXTextInput = ({ fieldName, fieldType, fieldPlaceholder, ...rest }) => (
        <Field name={fieldName} id={fieldName} type={fieldType} placeholde={fieldPlaceholder} >
            {({ field, form }) => (
                <TextBox {...rest}
                    name={field.name}
                    placeholder={fieldPlaceholder}
                    mode={fieldType}
                    onChange={event => { form.setFieldValue(field.name, event.component.option("value"), true) }}
                    onFocusOut={event => { form.setFieldTouched(field.name) }}
                    value={field.value}
                />
            )}
        </Field>
    );
    const DXComboInput = ({ fieldName, fieldType, fieldPlaceholder, ...rest }) => (
        <Field name={fieldName} id={fieldName} type={fieldType} placeholde={fieldPlaceholder} >
            {({ field, form }) => (
                <SelectBox {...rest}
                    name={field.name}
                    placeholder={fieldPlaceholder}
                    mode={fieldType}
                    onValueChanged={event => {
                        form.setFieldValue(field.name, event.component.option("value"), true)
                    }}
                    onFocusOut={event => { form.setFieldTouched(field.name) }}
                    value={field.value}
                />
            )}
        </Field>
    );

    // A custom validation function. This must return an object
    // which keys are symmetrical to our values/initialValues
    const validateCreate = values => {
        const errors = {};

        if (!values.name) {
            errors.name = 'Requerido';
        } else if (values.name.length > 30) {
            errors.name = 'Must be 30 characters or less';
        }

        if (!values.email) {
            errors.email = 'Requerido';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }

        return errors;
    };

    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }
    const jsonDataSource = new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: () => {
            return fetch(`${process.env.PUBLIC_URL}/api/procedures/Get`)
                .then(handleErrors)
                .then(response => response.json())
                .then(result => {
                    // You can process the response here
                    setProcedureCountState(result.response.length);
                    return result.response;
                })
                .catch(() => { throw 'Network error' });
        }
    });

    const loadDefaultProcedures = async () => {
        fetch(`${process.env.PUBLIC_URL}/api/procedures/GetDefaultProcedures`)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                console.log(result.response)
                setDefaultProceduresState(result.response)
            })
            .catch(() => { throw 'Network error' });
    };

    const importProcedure = async (importString) => {
        setCreateDefaultProcedureState({
            loading: true, error: false, result: null
        });
        //alert(JSON.stringify(values, null, 2));
        const response = await fetch(`${process.env.PUBLIC_URL}/api/procedures/ImportProcedure`, {
            method: 'POST',
            body: JSON.stringify(importString),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        setCreateDefaultProcedureState({
            loading: false, error: (data.response == null), result: (data.response != null ? data.response : data.message)
        });
        hideDefaultProcedureDialog();
        hideImportDialog();
        if (!createDefaultProcedureState.error) {
            showImportSucessDialog("Tipo de trámite creado");
        }
    }


    const loadAll = async () => {
        //setLoadingState(true);
        Promise.all([loadDefaultProcedures()]).then(() => {
            //setLoadingState(false);
        });
    }
    useEffect(loadAll, []);


    const importButtonClick = useCallback((event) => {
        showImportDialog("Importar tipo de trámite");
        event.element.classList.remove("dx-state-active");
        event.element.classList.remove("dx-state-focused");
    }, [])
    const newButtonClick = useCallback((event) => {
        toggleNewSubmenuOpenState();
        event.element.classList.remove("dx-state-active");
        event.element.classList.remove("dx-state-focused");
    }, [])
    const newButtonAttrs = { class: "procedures-component-newbutton", id: "procedures-component-newbutton" };
    const stateCellRenderer = useCallback((data) => {
        let stateName = "Cargando...";
        let stateBGColor = "#FFFFFF";
        if (data.data.state == -1) {
            stateName = "Oculto";
            stateBGColor = "#FF5252";
        } else if (data.data.state == 0) {
            stateName = "Desactivado";
            stateBGColor = "#BDBDBD";
        } else if (data.data.state == 1) {
            stateName = "Activo";
            stateBGColor = "#8BC34A";
        }
        return <div style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}><div style={{ backgroundColor: stateBGColor, width: 10, minWidth: 10, height: 10, borderRadius: 8, marginRight: 10, boxShadow: "unset", alignSelf: "center" }}></div>{stateName}</div>
    }, []);
    const verButtonRenderer = useCallback((data) => {
        return <div className="dx-button" style={{ padding: 5, width: 50 }}><img src={imgView} className="dx-icon dx-link-icon" style={{ width: 20, height: 20 }} title="Ver tipo de trámite" /></div>
    }, []);
    const verButtonClick = useCallback((e) => {
        let currentUrl = history.location.pathname;
        let backlog = [];
        if (history.location.state != undefined && history.location.state.comesFrom != undefined && history.location.state.comesFrom != "") {
            backlog = history.location.state.comesFrom;
        }
        backlog.push(currentUrl);
        history.replace(`/procedure/${e.row.data.id}`, { comesFrom: backlog });
    }, []);

    return (
        <div className="procedures-component">
            <div className="container">
                <div className="flex">
                    <BackButton text="Ir atrás" to="" history={history} />
                    <div className="header">
                        <h1>Tipos de trámites&nbsp;</h1>
                        <h4>({procedureCountState} trámites)</h4>
                        <div className="space" />
                    </div>
                    <DataGrid
                        dataSource={jsonDataSource}
                        noDataText="Sin tipos de trámite configurados"
                        keyExpr="id"
                        showColumnLines={false}
                        showRowLines={true}
                        showBorders={false}
                        width="100%"
                        height={useWindowSize().height - (156 + 50)}
                    >
                        <DGLoadPanel
                            shadingColor="rgb(92 92 92 / 80%)"
                            enabled={true}
                            showIndicator={true}
                            showPane={true}
                            indicatorSrc={imgSpinner}
                            text="Cargando..."
                        />
                        <Sorting mode="multiple" />
                        <Scrolling mode="standard" useNative={true} />
                        <SearchPanel visible={true} placeholder="Buscar..." />
                        <Toolbar>
                            <Item locateInMenu="auto"
                                location="after">
                                <Button
                                    text="Importar"
                                    type="default"
                                    visible={ navigator.clipboard != undefined && navigator.clipboard.readText != undefined }
                                    useSubmitBehavior={false}
                                    onClick={importButtonClick}
                                />
                            </Item>
                            <Item locateInMenu="auto"
                                location="after">
                                <Button
                                    elementAttr={newButtonAttrs}
                                    text="Nuevo tipo de trámite"
                                    type="default"
                                    useSubmitBehavior={false}
                                    onClick={newButtonClick}
                                />
                            </Item>
                            <Item locateInMenu="auto"
                                location="after"
                                name="searchPanel" />
                        </Toolbar>
                        <Editing
                            mode="row"
                            allowUpdating={true}
                            allowDeleting={true}
                            allowAdding={false} />
                        <Column dataField="name" caption="Nombre" dataType="string" defaultSortOrder="asc" defaultSortIndex="1" />
                        <Column dataField="state" visible={false} caption="Estado" dataType="string" defaultSortOrder="desc" defaultSortIndex="0" cellRender={stateCellRenderer} />
                        <Column dataField="dateModif" caption="F. última modificación" dataType="string" />
                        <Column caption="Acciones" type="buttons" dataType="string">
                            <DGButton
                                text="Editar"
                                stylingMode="outlined"
                                type="success"
                                icon={imgView}
                                hint="Ver tipo de trámite"
                                onClick={verButtonClick}
                                render={verButtonRenderer}
                            />
                        </Column>
                    </DataGrid>
                </div>
            </div>
            <Popover
                target="#procedures-component-newbutton"
                position="bottom"
                onHiding={() => {
                    setNewSubmenuOpenState(false);
                }}
                width={200}
                visible={newSubmenuOpenState}
                onContentReady={
                    (args) => {
                        var html = args.component.content();
                        html.style.padding = 0;
                        html.style.borderRadius = "8px";
                    }
                }
            >
                <List
                    elementAttr={{ class: "procedures-component-newbutton-list" }}
                    noDataText="--"
                    displayExpr="text"
                    dataSource={[{ text: "Desde cero", id: 0 }, { text: "Trámite predeterminado", id: 1 }]}
                    onItemClick={(e) => {
                        if (e.itemData.id == 0) {
                            history.push("./procedure/-1");
                            setNewSubmenuOpenState(false);
                        } else {
                            showDefaultProcedureDialog("Tipos de trámite predeterminados");
                            setNewSubmenuOpenState(false);
                        }
                    }}
                    allowItemDeleting={false}>
                </List>
            </Popover>

            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={mailDialogState.popupVisible}
                onHiding={hideMailDialog}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={mailDialogState.title}
                container=".dx-viewport"
                height="auto"
                width="auto"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        type: "default",
                        text: 'Cerrar',
                        onClick: () => {
                            hideMailDialog();
                        },
                    }}
                />
                <div style={{ display: "flex", "justifyContent": "center" }}><img src={check_rounded_big} /></div>
                <h4 style={{ textAlign: "center", fontFamily: 'roboto-regular' }} >
                    Hemos enviado un correo electrónico al trámite que acabas de crear.
                </h4>
                <h4 style={{ textAlign: "center", fontFamily: 'roboto-bold' }}>
                    <b>{mailDialogState.target}</b>
                </h4>
                <h4 style={{ textAlign: "center", fontFamily: 'roboto-regular' }} >
                    En él recibirá las instrucciones para acceder a la plataforma.
                </h4>
                <p style={{ textAlign: "center", fontFamily: 'roboto-regular' }} >Si te has equivocado de correo electrónico, elimina el trámite y crea uno nuevo.</p>
            </Popup>
            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={defaultProcedureDialogState.popupVisible}
                onHiding={hideDefaultProcedureDialog}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={defaultProcedureDialogState.title}
                container=".dx-viewport"
                height="auto"
                width="auto"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        type: "default",
                        text: 'Cancelar',
                        enabled: (!createDefaultProcedureState.loading),
                        onClick: () => {
                            hideDefaultProcedureDialog();
                        },
                    }}
                />
                <h4 style={{ textAlign: "center", fontFamily: 'roboto-regular' }} >
                    Elige un tipo de trámite predeterminado y se creará automáticamente.
                </h4>
                <List
                    elementAttr={{ class: "procedures-component-defaultprocedures-list" }}
                    noDataText="Lo sentimos, no hay trámites predeterminados disponibles en este momento."
                    width="615"
                    enabled={!createDefaultProcedureState.loading}
                    displayExpr="name"
                    dataSource={defaultProceduresState}
                    onItemClick={async (e) => {
                        if (e.itemData.contenido != null && e.itemData.contenido != "") {
                            await importProcedure(e.itemData.contenido);
                        }
                    }}
                    allowItemDeleting={false}>
                </List>
            </Popup>
            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={importSucessDialogState.popupVisible}
                onHiding={hideImportSucessDialog}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={importSucessDialogState.title}
                container=".dx-viewport"
                height="auto"
                width="auto"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        type: "default",
                        text: 'Cerrar',
                        onClick: () => {
                            hideImportSucessDialog();
                        },
                    }}
                />
                <div style={{ display: "flex", "justifyContent": "center" }}><img src={check_rounded_big} /></div>
                <h4 style={{ textAlign: "center", fontFamily: 'roboto-regular' }} >
                    Se ha creado el tipo de trámite satisfactoriamente.
                </h4>
                <p style={{ textAlign: "center", fontFamily: 'roboto-regular' }} >Puedes modificar el trámite desde el listado que se muestra en esta misma ventana.</p>
            </Popup>
            <Popup
                animation={{ hide: null }}
                onShown={async (e) => {
                    e.component.repaint();
                }}
                visible={importDialogState.popupVisible}
                onHiding={hideImportDialog}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={importDialogState.title}
                container=".dx-viewport"
                height="auto"
                width="auto"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        type: "default",
                        visible: (navigator.clipboard != undefined && navigator.clipboard.readText != undefined && importDialogValueState != "" && importDialogObjectState != null),
                        enabled: !createDefaultProcedureState.loading,
                        text: 'Importar',
                        onClick: async () => {
                            await importProcedure(importDialogValueState);
                            hideImportDialog();
                        },
                    }}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        type: "default",
                        text: 'Cerrar',
                        onClick: () => {
                            hideImportDialog();
                        },
                    }}
                />
                {(navigator.clipboard != undefined && navigator.clipboard.readText != undefined && importDialogValueState!="" && importDialogObjectState!=null ?
                    <>
                        <p style={{ textAlign: "left", fontFamily: 'roboto-regular' }} >
                            Pulsa el botón importar para confirmar la importación del código de importación que se muestra a continuación:
                        </p>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <div style={{ width: 50 }} /><b>Nombre: </b><div style={{ width: 20 }} /><span>{importDialogObjectState.Name}</span><div style={{ width: 50 }} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <div style={{ width: 50 }} /><b>Datos: </b><div style={{ width: 20 }} /><span>{importDialogObjectState.Data.length}</span><div style={{ width: 50 }} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <div style={{ width: 50 }} /><b>Documentos: </b><div style={{ width: 20 }} /><span>{importDialogObjectState.Docs.length}</span><div style={{ width: 50 }} />
                            </div>
                        </div>
                        <textarea style={{ margin: "auto", marginTop:20, width: 700, height:170 }} disabled={true} value={importDialogValueState} ></textarea>
                    </>
                    :
                    <>
                        <h4 style={{ textAlign: "center", fontFamily: 'roboto-regular' }} >
                            No se ha encontrado ningún código de importación de tipo de trámite en el portapapeles.
                        </h4>
                        <p style={{ textAlign: "center", fontFamily: 'roboto-regular' }} >Si has copiado un código de importación es posible que debas permitir al navegador el acceso al portapapeles.</p>
                    </>
                )}
            </Popup>
        </div>
    )
}
