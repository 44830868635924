import { Button, Popup, TextBox } from 'devextreme-react';
import React, { useEffect, useRef, useState } from 'react'
import noDoc from '../../NO_DOC.pdf';
import imgEdit from '../../img/edit.png';
import imgDelete from '../../img/delete.png';
import imgUpload from '../../img/upload.png';
import imgDownload from '../../img/download.png';
import "./DocumentViewer.css";
import { Field, Form, Formik } from 'formik';
import { Position, ToolbarItem } from 'devextreme-react/popup';
import { Document, Page, pdfjs } from 'react-pdf';
import useWindowSize from '../../hooks/useWindowSizze';

export const DocumentViewer = ({ elmId, selectedDocB64, viewerWidth }) => {

    

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    });

    const [selectedDocumentNumPages, setSelectedDocumentNumPages] = useState(null);
    const [selectedDocumentPageNumber, setSelectedDocumentPageNumber] = useState(1);
    const [calculatedWidth, setCalculatedWidth] = useState(0);
    const [calculatedHeight, setCalculatedHeight] = useState(0);
    const windowSize = useWindowSize();

    function onSelectedDocumentLoadSuccess({ numPages }) {
        setSelectedDocumentNumPages(numPages);
        setSelectedDocumentPageNumber(1);
    }

    function selectedDocumentChangePage(offset) {
        setSelectedDocumentPageNumber(selectedDocumentPrevPageNumber => selectedDocumentPrevPageNumber + offset);
    }

    function selectedDocumentPreviousPage() {
        selectedDocumentChangePage(-1);
    }

    function selectedDocumentNextPage() {
        selectedDocumentChangePage(1);
    }

    useEffect(() => {
        const theMatches = viewerWidth.match(/(\d+)([\D\w]*)/);
        const theNumber = theMatches[1];
        const theUnit = theMatches[2];
        let theWidth = theNumber;
        if (theUnit == "%") {
            if ((document.getElementById(`${elmId}`) == undefined) || (document.getElementById(`${elmId}`) == null)) {
                setCalculatedWidth(0);
                setCalculatedHeight(0);
                return 0;
            }
            theWidth = (document.getElementById(`${elmId}`).getBoundingClientRect().width * (theNumber / 100))
            setCalculatedHeight(document.getElementById(`${elmId}`).getBoundingClientRect().height * (1))
        } else {

        }
        console.log("setCalculatedWidth " + theWidth);
        setCalculatedWidth(theWidth);
    }, [viewerWidth, windowSize]);   

    return (

        <div className={`document-viewer-component`} id={`${elmId}`} style={{ width: "100%" }}>
            {selectedDocB64 == null || selectedDocB64 == ""
                ?
                <div style={{ height: `${calculatedHeight}px`, padding: 20, backgroundColor: "white", border: "solid 1px var(--azul-df)", fontStyle: "bold", display: "flex", justifyContent: "center", alignItems: "center" }}  >Sin documento seleccionado</div>
                :
                (
                    selectedDocB64.startsWith("data:application/pdf;base64,") ?
                        <div className="document-viewer-component-viewer">
                            <Document
                                error="Ha ocurrido un error al tratar de visualizar el documento. Desc�rgalo para verlo."
                                loading="Cargando el documento..."
                                noData="Sin documento seleccionado."
                                file={`${(encodeURI(selectedDocB64))}`}
                                onLoadSuccess={onSelectedDocumentLoadSuccess}
                            >
                                <Page
                                    error="Ha ocurrido un error al cargar la p�gina."
                                    loading="Cargando la p�gina..."
                                    noData="Sin datos en la p�gina"
                                    width={calculatedWidth}
                                    pageNumber={selectedDocumentPageNumber} />
                            </Document>
                            <div className="document-viewer-component-paginator">
                                <p style={{ "alignSelf": "center" }}>
                                    P&aacute;gina {selectedDocumentPageNumber || (selectedDocumentNumPages ? 1 : '--')} de {selectedDocumentNumPages || '--'}
                                </p>
                                <div className="local-documents-component-viewer-paginator-buttons">
                                    <Button
                                        width="100%"
                                        type="default"
                                        style={{ marginLeft: 5, marginRight: 5 }}
                                        disabled={selectedDocumentPageNumber <= 1}
                                        onClick={selectedDocumentPreviousPage}
                                        text="Anterior"
                                    />
                                    <Button
                                        width="100%"
                                        type="default"
                                        style={{ marginLeft: 5, marginRight: 5 }}
                                        disabled={selectedDocumentPageNumber >= selectedDocumentNumPages}
                                        onClick={selectedDocumentNextPage}
                                        text="Siguiente"
                                    />
                                </div>
                            </div>
                        </div>
                        //<iframe style={{ width: "25%", border: "solid 1px var(--azul-df)", }} src={`data:application/pdf;base64,${(encodeURI(selectedDocB64State))}#view=FitH`} ></iframe>
                        :
                        <div style={{ height: `${calculatedHeight}px`, padding: 20, backgroundColor: "white", border: "solid 1px var(--azul-df)", fontStyle: "bold", display: "flex", justifyContent: "center", alignItems: "center" }}  >El documento seleccionado no puede visualizarse. Descarga el documento para verlo.</div>
                )
            }
        </div>
    )
}
