import React from 'react'
import { Redirect, Route } from 'react-router'

export const PublicRoute = ({
        children,
        isAuthenticated,
        ...rest
    }) => {
    return (
        <Route  {...rest} >
            {!isAuthenticated ? (
                children
            ) : (
                <Redirect to='/' />
            )}
        </Route >
    )
}
//export const PublicRoute = ({
//    isAuthenticated,
//    component: Component,
//    ...rest
//}) => {
//    return (
//        <Route  {...rest}
//            component={(props) => {
//                return (!isAuthenticated)
//                    ? (<Component {...props} />)
//                    : (<Redirect to="./" />)
//            }}
//        />
//    )
//}