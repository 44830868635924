import { CheckBox, List } from 'devextreme-react';
import { ItemDragging } from 'devextreme-react/list';
import React from 'react'
import { disable } from 'workbox-navigation-preload';
import "./ProcedureStateChooser.css";

export const ProcedureStateChooser = ({ itemsState, setItemsState, disabled }) => {

    const onDragStart=(e) => {
        e.itemData = itemsState[e.fromIndex];
    }

    const onAdd = (e) =>  {
        const tasks = itemsState;
        setItemsState(
            [...tasks.slice(0, e.toIndex), e.itemData, ...tasks.slice(e.toIndex)],
        );
    }

    const onRemove = (e) =>  {
        const tasks = itemsState;
        setItemsState(
            [...tasks.slice(0, e.fromIndex), ...tasks.slice(e.fromIndex + 1)],
        );
    }

    const onReorder = (e) =>  {
        const tasks = itemsState;
        const tmpTasks = [...tasks.slice(0, e.fromIndex), ...tasks.slice(e.fromIndex + 1)];
        setItemsState(
            [...tmpTasks.slice(0, e.toIndex), e.itemData, ...tmpTasks.slice(e.toIndex)],
        );
    }
    const onCheckChanged = (itemData, check, value) => {
        const tasks = itemsState;
        const task = tasks.find((e) => e.state.id == itemData.state.id);
        const taskIndex = tasks.indexOf(task);
        if (check == "shown") {
            if (!task.initial) {
                task[check] = value;
            }
        }else if (check == "initial") {
            if (value) {
                tasks.map((e) => {
                    e["initial"] = false;
                });
                task[check] = value;
            }
        } else {
            task[check] = value;
        }
        setItemsState(
            [...tasks.slice(0, taskIndex), task, ...tasks.slice(taskIndex+1)],
        );
    }

    return (
        <div className="procedure-state-chooser-component">
            <h4 style={{ marginBottom: 0 }} >Estados que se mostrarán en el trámite</h4>
            <p style={{ marginTop: 6 }} >Selecciona los estados a mostrar en este tipo de trámite y ordénalos arrastrándolos a la posición deseada. Selecciona el estado inicial (solo puedes seleccionar uno). El estado “Finalizado” será obligatorio y el estado final de todos los tipos de trámite.</p>
            <div className="listHeader" style={{marginBottom: 10, width: "calc(100% - 50px)", cursor: "default", display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
                <div style={{ width: "100%" }}>Nombre del estado</div>
                <div style={{ width: "100%" }}>Estados a mostrar</div>
                <div style={{ width: "100%" }}>Estado inicial</div>
                <div style={{ width: "100%" }}>Estado final</div>
            </div>
            <List
                disabled={disabled}
                selectionMode="none"
                items={itemsState}
                keyExpr={function (item) {
                    return item && item.state && item.state.id;
                }}
                displayExpr={function (item) {
                    return item && item.state && item.state.name;
                }}
                repaintChangesOnly={false}
                itemRender={(item) => (
                    <div className="list-item" style={{ cursor: "default", display: "flex", flexDirection: "row", flexWrap: "nowrap" }} >
                        <b style={{ width: "100%", color:"var(--azul-df)", fontSize: 16 }} >{item.state.name}</b>
                        <div style={{ width: "100%" }} >{(item.state.id != 6 && item.state.id != 1) && !item.inital ? <CheckBox disabled={disabled} value={item.shown} onValueChanged={(e) => { onCheckChanged(item, "shown", e.value) }} /> : null}</div>
                        <div style={{ width: "100%" }} >{(item.state.id != 6 && item.state.id != 1) && item.shown ? <CheckBox disabled={disabled} className="round-check" value={item.initial} onValueChanged={(e) => { onCheckChanged(item, "initial", e.value) }} /> : null }</div>
                        <div style={{ width: "100%" }} >{item.state.id == 6 ? <CheckBox disabled={disabled} elementAttr={{ class:"round-check" }} readOnly={true} value={ true } /> : null}</div>
                    </div>
                )}
            >
                <ItemDragging
                    allowReordering={!disabled}
                    group="tasks"
                    data="plannedTasks"
                    onDragStart={onDragStart}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    onReorder={onReorder} >
                </ItemDragging>
            </List>
        </div>
    )
}
