import {Button, Gallery, LoadPanel} from 'devextreme-react'
import React, { useState } from 'react'
import "./Dealer.css";
import { Link, useHistory } from 'react-router-dom';
import imgSpinner from '../../img/spinner.gif';
import checkGreen from '../../img/check_green.png';
import closeRed from '../../img/close_red.png';

export const Dealer = ({ dealer, loadingCounters, setDealerState }) => {

    let history = useHistory();

    const dataSource = [
        { number: dealer.counters["2"] || 0, name: "Pendiente" },
        { number: dealer.counters["3"] || 0, name: "En trámite" },
        { number: dealer.counters["4"] || 0, name: "Incidencia" },
        { number: dealer.counters["5"] || 0, name: "En revisión" },
        { number: dealer.counters["-1"] || 0, name: "Otros" },
    ];
    const renderGalleryItem = (itemData) => {
        return (
            <div>
                <p><h4 style={{ margin: 0, color: "#002C5D" }}>{itemData.number}</h4></p>
                <p><b style={{ color: "#002C5D" }}>{itemData.name}</b></p>
            </div>
        );
    }



    

    return (
        <div className="dealer-component">
            <div className="container">
                
                {
                    dealer.estado == 0 ?
                    <>
                        <div className="header pending">
                            <h3 title={`${dealer.name}`} >{dealer.name}</h3>
                            <div style={{ flex: "auto" }} />
                            <span title={`${dealer.clienteDF}`} >{dealer.clienteDF}</span>
                        </div>
                        <div className={`body pending`}>
                                <span style={{ overflowY: "scroll" }} >{dealer.descripcion != null && dealer.descripcion != ""? dealer.descripcion:"Este concesionario está pendiente de aprobación. No se ha proporcionado información adicional para su aprobación."}</span>
                        </div>
                        <div className="footer pending">
                            <Button style={{ marginBottom: "12px", marginRight: "10px", backgroundColor: "white"}}
                                text="Rechazar"
                                type="normal"
                                stylingMode="outlined"
                                icon={closeRed}
                                useSubmitBehavior={false}
                                    onClick={event => {
                                        setDealerState(dealer, 2, () => {
                                            alert("ok");
                                        }, () => {
                                            alert("ko");
                                        })
                                        event.element.classList.remove("dx-state-active");
                                        event.element.classList.remove("dx-state-focused");
                                    }
                                }
                            />
                            <Button style={{ marginBottom: "12px", marginRight: "10px", backgroundColor: "white" }}
                                text="Aprobar"
                                type="normal"
                                stylingMode="outlined"
                                icon={checkGreen}
                                useSubmitBehavior={false}
                                    onClick={event => {
                                        setDealerState(dealer, 1, () => {
                                            alert("ok");
                                        }, () => {
                                            alert("ko");
                                        })
                                        event.element.classList.remove("dx-state-active");
                                        event.element.classList.remove("dx-state-focused");
                                    }
                                }
                            />
                        </div>
                    </>
                    :
                    <>
                        <div className="header">
                            <h3 title={`${dealer.name}`} >{dealer.name}</h3>
                            <div style={{ flex: "auto" }} />
                            <span title={`${dealer.clienteDF}`} >{dealer.clienteDF}</span>
                        </div>
                        <div className={`body counterPosition_${dealer.id}`}>
                            {
                                loadingCounters ?
                                    <LoadPanel
                                        position={{ of: `.counterPosition_${dealer.id}`}}
                                        shadingColor="rgba(0,0,0,0)"
                                        visible={true}
                                        showIndicator={true}
                                        showPane={false}
                                        closeOnOutsideClick={false}
                                        indicatorSrc={imgSpinner}
                                        message="Cargando..."
                                    />
                                :
                                <Gallery
                                    dataSource={dataSource}
                                    height={100}
                                    width="100%"
                                    itemRender={renderGalleryItem}
                                    initialItemWidth={70}
                                    showNavButtons={true}
                                    showIndicator={false}
                                    loop={false}
                                />
                            }
                        </div>
                        <div className="footer">
                            <Link replace={true} className="btn" to={{ pathname: `../registries/${dealer.id}`, comesFrom: history.location.pathname }} ><div style={{ textAlign: "center"}} >Acceder</div></Link>
                        </div>
                    </>
                }
                
            </div>
        </div>
    )
}
